import styled, { css } from "styled-components";
import { fontSize, spacing } from "../../../theme";
import { deviceUp } from "../Grid";

type FilterTabProps = {
  active?: boolean;
  backgroundColor?: string;
};

const hoverAndActiveStyles = css`
  color: ${({ theme }) => theme.color.primary200};
  background-color: ${({ theme }) => theme.color.secondary100};

  svg g {
    stroke: ${({ theme }) => theme.color.primary200};
    fill: ${({ theme }) => theme.color.primary200};
  }
`;

export const FilterTab = styled.button<FilterTabProps>`
  display: inline-block;
  text-decoration: none;
  height: 32px;
  border-radius: 16px;
  position: relative;
  ${fontSize("xxs", "body")};
  color: ${({ theme }) => theme.color.monochrome500};
  background-color: ${({ theme }) => theme.color.bgPanel200};
  transition: all 0.2s ease-in-out;
  border: none;
  margin-right: ${spacing("xs")};
  margin-bottom: ${spacing("xs")};

  padding: ${spacing(
    "xxs",
    "s",
    "xxs",
    42
  )}; /* 2*spacing('s') + width of icon (18px) = 42 */

  &:hover {
    text-decoration: none;
    cursor: pointer;
    ${hoverAndActiveStyles}
  }

  &:last-of-type {
    margin-right: 0;
  }

  svg {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${spacing("s")};
    transition: all 0.2s ease-in-out;

    g {
      fill: ${({ theme }) => theme.color.monochrome500};
      stroke: ${({ theme }) => theme.color.monochrome500};
    }
  }

  @media ${deviceUp.m} {
    ${fontSize("m", "body")};
    height: 40px;
    border-radius: 20px;
    padding: ${spacing(
      "xs",
      "m",
      "xs",
      50
    )}; /* 2*spacing('m') + width of icon (18px) = 50 */
    margin-right: ${spacing("s")};

    svg {
      width: 24px;
      height: 24px;
      left: ${spacing("m")};
    }
  }

  ${({ active }) => active && hoverAndActiveStyles};
`;
