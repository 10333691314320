import * as React from "react";
import { SVGProps } from "react";

const SvgMobileInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <circle
        vectorEffect="non-scaling-stroke"
        cx={17.414}
        cy={7.565}
        r={0.043}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.5,11.371V8.805h-0.428 M17.072,11.371h0.855 M17.5,5.384L17.5,5.384c2.224,0,4.062,1.839,4.062,4.062l0,0 c0,2.224-1.839,4.062-4.062,4.062l0,0c-2.224,0-4.062-1.839-4.062-4.062l0,0C13.438,7.223,15.234,5.384,17.5,5.384z M10.5,19.5h3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.5,13.509V19.5c0,1.1-0.9,2-2,2h-7c-1.1,0-2-0.9-2-2v-15c0-1.1,0.9-2,2-2h9v2.884"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgMobileInfo;
