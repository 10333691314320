import * as React from "react";
import { SVGProps } from "react";

const SvgChargeToBill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.7,19.6v-3.8 M5.8,17.7h3.8"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.7,13.9L7.7,13.9c2.1,0,3.8,1.7,3.8,3.8s-1.7,3.8-3.8,3.8l0,0 c-2.1,0-3.8-1.7-3.8-3.8C3.9,15.6,5.6,13.9,7.7,13.9z"
        fill="none"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.581,10.117h-3.858 M17.581,12.981h-3.858 M17.581,15.845h-3.858 M11.338,10.117h-0.987"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.5,13.92V6.791c0-0.599,0.259-1.168,0.716-1.559l2.606-2.237c0.373-0.32,0.848-0.494,1.342-0.494H20v16.097 c0,1.045-0.618,1.903-1.929,1.903h-7.82"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.553,6.328h3.786V2.663"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgChargeToBill;
