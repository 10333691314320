import React, { FC } from "react";
import * as S from "./Headline.styles";
import { HeadlineProps } from "./Headline.types";

export const Headline: FC<HeadlineProps> = ({
  styleAs = "h1",
  text,
  align = "left",
  bold = false,
  ...props
}) => {
  return (
    <S.Headline
      styleAs={styleAs}
      align={align}
      bold={bold}
      dangerouslySetInnerHTML={{ __html: unescape(text ?? "") }}
      {...props}
    />
  );
};
