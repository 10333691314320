import * as React from "react";
import { SVGProps } from "react";

const SvgNightVisionGoggles = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 59.9 73"
    xmlSpace="preserve"
    enableBackground="new 0 0 59.9 73"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M25.9,44l6.8-0.1c0.3,0,0.5-0.2,0.5-0.4c0.3-1.4,0.5-2.9,0.4-4.4c0-0.3-0.3-0.5-0.6-0.5l0,0l-6.4,0.1
      c-0.3,0-0.5,0.2-0.5,0.5c0,0,0,0.1,0,0.1c0.3,0.8,0.3,1.6,0.1,2.4c-0.1,0.5-0.4,1-0.8,1.5c-0.2,0.2-0.1,0.6,0.1,0.7
      C25.7,44,25.8,44,25.9,44L25.9,44z M26.8,42.9c0.2-0.3,0.4-0.6,0.5-1c0.2-0.8,0.1-1.5-0.1-2.3l5.2-0.1c0,1.1-0.1,2.2-0.3,3.3
      L26.8,42.9L26.8,42.9z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M29.6,10.5c-16.5,0-30,13.3-30,29.6c0,2.9,0.4,5.9,1.3,8.7c0.1,0.4,0.5,0.6,0.8,0.6h1.7c1.5,0,2.8,1.2,2.8,2.8
      v6.2c0,0.2,0.1,0.4,0.2,0.6c1.7,2,3.6,3.7,5.7,5.2c0.4,0.3,1,0.2,1.2-0.2c0.1-0.2,0.2-0.5,0.1-0.7c-0.1-0.7-0.2-1.2-0.1-1.6
      c0.1-0.4,0.2-0.5,0.3-0.6c0.1-0.1,0.6-0.1,1.2,0.4s1.4,1.3,1.9,2.3c0.6,1,0.9,2.1,0.9,2.9c0,0.3,0.2,0.7,0.6,0.8
      c3.6,1.5,7.4,2.2,11.2,2.3l0,0c3.9,0,7.7-0.8,11.2-2.2c0.3-0.1,0.5-0.4,0.6-0.8c0-0.8,0.4-1.9,0.9-2.9c0.6-1.1,1.3-1.9,1.9-2.3
      c0.7-0.5,1.1-0.5,1.2-0.4c0.1,0,0.2,0.2,0.3,0.6c0.1,0.4,0.1,1-0.1,1.6c-0.1,0.5,0.2,1,0.7,1.1c0.2,0.1,0.5,0,0.7-0.1
      c2-1.4,3.9-3.1,5.5-4.9c0.1-0.2,0.2-0.4,0.2-0.6v-6.5c0-1.5,1.2-2.8,2.7-2.8h2c0.4,0,0.7-0.3,0.8-0.6c0.9-2.8,1.3-5.7,1.3-8.7l0,0
      C59.6,23.8,46.1,10.5,29.6,10.5L29.6,10.5L29.6,10.5z M29.6,12.2c15.6,0,28.2,12.4,28.2,27.8c0,2.5-0.5,5.1-1.2,7.5h-1.2
      c-2.5,0-4.5,2-4.5,4.5v6.1c-1,1.2-2.3,2.1-3.5,3.1c0,0,0-0.1,0-0.1c-0.1-0.7-0.5-1.4-1.2-1.8l0,0c-1-0.5-2.1-0.2-3,0.5
      c-0.9,0.7-1.8,1.7-2.5,2.9c-0.5,1-0.8,2-0.9,3c-3.3,1.3-6.7,2-10.2,2c-3.5,0-7-0.8-10.2-2.1c-0.1-1-0.4-2-0.9-3
      c-0.7-1.2-1.5-2.3-2.5-2.9c-0.9-0.7-2.1-1-3-0.5l0,0c-0.7,0.4-1,1-1.2,1.8c0,0,0,0.1,0,0.1c-1.3-1.1-2.6-2.1-3.7-3.4v-5.8
      c0-2.5-2-4.5-4.5-4.5h-1c-0.7-2.5-1.1-5-1.2-7.5C1.4,24.7,14,12.2,29.6,12.2L29.6,12.2L29.6,12.2z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.3,31.8c-5,0-9,4.1-9,9s4.1,9,9,9s9-4,9-9S23.3,31.8,18.3,31.8z M18.3,33.6c4,0,7.3,3.2,7.3,7.3
      s-3.2,7.3-7.3,7.3s-7.3-3.2-7.3-7.3S14.3,33.6,18.3,33.6z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.3,34.9c-3.2,0-5.9,2.6-5.9,5.9c0,3.3,2.6,5.9,5.9,5.9s5.9-2.6,5.9-5.9C24.3,37.6,21.6,34.9,18.3,34.9z
      M18.3,36.7c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1s-4.1-1.8-4.1-4.1C14.2,38.5,16.1,36.7,18.3,36.7z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M40.9,31.8c-5,0-9,4.1-9,9s4,9,9,9s9-4,9-9S45.9,31.8,40.9,31.8z M40.9,33.6c4,0,7.3,3.2,7.3,7.3
      s-3.2,7.3-7.3,7.3s-7.3-3.2-7.3-7.3S36.9,33.6,40.9,33.6z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M40.9,34.9c-3.2,0-5.9,2.6-5.9,5.9c0,3.3,2.6,5.9,5.9,5.9s5.9-2.6,5.9-5.9C46.8,37.6,44.2,34.9,40.9,34.9z
      M40.9,36.7c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1c-2.3,0-4.1-1.8-4.1-4.1C36.8,38.5,38.6,36.7,40.9,36.7z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M29.6,15.3c-5,0-9,4.1-9,9s4.1,9,9,9s9-4,9-9S34.6,15.3,29.6,15.3z M29.6,17.1c4,0,7.3,3.2,7.3,7.3
      s-3.2,7.3-7.3,7.3s-7.3-3.2-7.3-7.3S25.6,17.1,29.6,17.1z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M29.6,18.5c-3.2,0-5.9,2.6-5.9,5.9s2.6,5.9,5.9,5.9s5.9-2.6,5.9-5.9S32.9,18.5,29.6,18.5z M29.6,20.2
      c2.3,0,4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1s-4.1-1.8-4.1-4.1S27.3,20.2,29.6,20.2z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M48.7,36.8c-0.5-0.1-1,0.1-1.1,0.6c-0.1,0.2-0.1,0.4,0,0.6c0.4,0.9,0.6,1.8,0.6,2.8c0,1.4-0.4,2.7-1.1,3.9
      c-0.3,0.4-0.1,1,0.3,1.2c0.1,0,0.1,0.1,0.2,0.1l9.7,3.1c0.5,0.1,1-0.1,1.1-0.6l0,0c0.7-2.5,1.1-5,1.2-7.5c0-0.4-0.2-0.7-0.6-0.9
      L48.7,36.8L48.7,36.8z M49.6,39l8.1,2.6c-0.1,1.9-0.4,3.7-0.9,5.5l-7.9-2.5c0.6-1.2,1.1-2.4,1.1-3.7l0,0C50,40.2,49.7,39.6,49.6,39
      L49.6,39L49.6,39z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.6,36.8L0.4,40.1C0,40.2-0.2,40.6-0.2,41c0.1,2.5,0.5,5.1,1.2,7.5c0.1,0.5,0.6,0.7,1.1,0.6l0,0l9.7-3.1
      c0.5-0.1,0.7-0.7,0.6-1.1c0-0.1-0.1-0.1-0.1-0.2c-0.7-1.2-1.1-2.5-1.1-3.9c0-1,0.2-1.9,0.6-2.8c0.2-0.4,0-1-0.5-1.2
      C11,36.8,10.8,36.8,10.6,36.8L10.6,36.8z M9.8,39c-0.1,0.6-0.4,1.2-0.4,1.8c0,1.3,0.5,2.6,1.1,3.8l-7.9,2.5
      c-0.5-1.8-0.8-3.7-0.9-5.5L9.8,39L9.8,39z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.9,26.9l-1.8,4.6c-0.1,0.2,0,0.4,0.2,0.6c1.2,1.2,2.4,2.2,3.7,3.2c0.3,0.2,0.5,0.2,0.6-0.1l0,0l1.7-4.3
      c0.1-0.2-0.1-0.5-0.3-0.7c0,0-0.1-0.1-0.1-0.1c-0.7-0.3-1.5-0.8-2.1-1.5c-0.4-0.4-0.8-1-1.1-1.5c-0.1-0.3-0.4-0.5-0.7-0.4
      C21,26.7,20.9,26.8,20.9,26.9L20.9,26.9z M21.6,28.1c0.2,0.3,0.4,0.7,0.7,1c0.6,0.6,1.3,1,2,1.4l-1.4,3.5c-1-0.7-1.9-1.5-2.7-2.3
      L21.6,28.1L21.6,28.1z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M35.1,35.7l4.2-3c0.2-0.1,0.2-0.3,0.2-0.6c-0.4-1.4-1-2.7-1.7-4c-0.1-0.2-0.4-0.3-0.6-0.2l0,0l-4,2.9
      c-0.2,0.1-0.2,0.5-0.1,0.7c0,0,0.1,0.1,0.1,0.1c0.5,0.6,0.9,1.2,1.1,2c0.1,0.5,0.2,1.1,0.2,1.6c0,0.3,0.2,0.5,0.4,0.6
      C35,35.8,35.1,35.8,35.1,35.7L35.1,35.7z M35.2,34.4c0-0.4,0-0.7-0.1-1c-0.2-0.7-0.6-1.4-1.1-1.9l3.3-2.4c0.5,0.9,0.9,1.9,1.2,2.9
      L35.2,34.4L35.2,34.4z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgNightVisionGoggles;
