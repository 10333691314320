import * as React from "react";
import { SVGProps } from "react";

const SvgTvDiscovery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,14.422c-3.387,0-4.922-3.16-4.98-3.305c-0.027-0.086-0.027-0.18,0-0.262 C7.078,10.734,8.613,7.578,12,7.578s4.922,3.16,4.98,3.305c0.027,0.086,0.027,0.18,0,0.262C16.922,11.266,15.387,14.422,12,14.422z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,12.5c-0.828-0.004-1.496-0.676-1.492-1.504c0-0.828,0.672-1.496,1.5-1.496c0.824,0,1.496,0.668,1.5,1.496 c0,0.828-0.668,1.5-1.496,1.504H12z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7,19.516h10"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.5,4.535h17v10.98c0,1.105-0.895,2-2,2h-15c-1.105,0-2-0.895-2-2v-8.98C2.5,5.43,3.395,4.535,4.5,4.535z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,19.516v-2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgTvDiscovery;
