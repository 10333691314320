import styled from "styled-components";

type LoadingScreenProps = {
  show?: boolean;
};

export const LoadingScreen = styled.div<LoadingScreenProps>`
  background: rgba(0, 0, 0, 0.8);
  z-index: 1002;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s 0.2s;
  opacity: 0;
  visibility: hidden;

  ${({ show }) => show && `visibility: visible; opacity: 1;`}
`;
