import * as React from "react";
import { SVGProps } from "react";

const SvgInfoCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,16.5v-6h-1 M11,16.5h2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12}
        cy={12}
        r={9.5}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={11.8}
        cy={7.6}
        r={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgInfoCircle;
