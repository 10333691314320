import * as React from "react";
import { SVGProps } from "react";

const SvgCloudUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.8,15.5c0,0,2.8,0.1,3.9-0.4c1.6-0.7,2.6-2.2,2.8-3.9c0.2-1.6-0.6-3.4-2-4.3c-0.7-0.4-1.5-0.6-2.3-0.7 c-0.5,0-0.9,0-1.4,0.1c-0.7-2.1-2.5-3.6-4.7-3.8C9.3,2.4,7.6,3.1,6.5,4.4C6,5,5.6,5.8,5.4,6.6C5.4,6.8,5.3,7.4,5.3,7.4 C4.7,7.6,4.2,7.9,3.8,8.2C2.5,9.4,2.2,11.4,2.9,13c0.7,1.4,2.1,2.5,3.7,2.5h2.6 M12,9.5v11.9 M8.8,13L12,9.5l3.2,3.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgCloudUpload;
