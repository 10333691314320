import * as React from "react";
import { SVGProps } from "react";

const SvgHeatingsystem = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.1,4.9c0-0.8,2.6-1.5,5.9-1.5s5.9,0.7,5.9,1.5 M7,19.1c0,0.8,2.2,1.5,5,1.5 M12,20.6c2.7,0,5-0.7,5-1.5  M6.1,4.9v12.4 M17.9,4.9v12.4 M6.1,17.3c0,0.8,2.6,1.5,5.9,1.5 M12,18.8c3.2,0,5.9-0.7,5.9-1.5 M7,18.3v0.8 M17,18.3v0.8 M12,14.3 V17 M9.7,14.3v2.2 M14.3,14.3v2.2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.2,11.3c0.7-0.9-0.3-1.5-1.6-2.6c-1.3-1.3-1.2-1.6-1.2-2.3c0-0.6,0.8-1.2,1.4-1.2c0,1.4,0.5,1.7,1.3,2.3 c1.3,1.1,2.1,1.8,1.4,2.8C14.1,10.8,13.6,10.8,13.2,11.3z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.2,11.3c-0.6,0.6-1,0.9-1.3,1.1c0.5-1-0.2-1.5-1.5-2.5C9.6,9.3,9.1,8.8,9.1,7.7c0-0.7,0.7-1.2,1.2-1.2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgHeatingsystem;
