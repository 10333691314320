import * as React from "react";
import { SVGProps } from "react";

const SvgNetworker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.5,3v4c0,1.1-0.9,2-2,2h-1c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2H16.5z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.5,9h1c1.1,0,2,0.9,2,2v2c0,1.1-0.9,2-2,2h-1c-1.1,0-2-0.9-2-2v-2C3.5,9.9,4.4,9,5.5,9z M17.5,14.5h1 c1.1,0,2,0.9,2,2v2c0,1.1-0.9,2-2,2h-1c-1.1,0-2-0.9-2-2v-2C15.5,15.4,16.4,14.5,17.5,14.5z M14,9v3 M8.5,12H18v2.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgNetworker;
