import * as React from "react";
import { SVGProps } from "react";

const SvgTickOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.555,15.336l1.676-2.125l6.409-8.125c0.291-0.369,0.737-0.58,1.206-0.571l0.61-0.007l0.875-0.013 L19.513,5.75l-0.915,1.399l-5.901,9.022l-1.738,2.566c-0.266,0.375-0.587,0.773-1.091,0.773c-0.455,0-0.77-0.327-1.038-0.642 l-5-5.526l-0.1-0.125c-0.163-0.215-0.246-0.48-0.235-0.75c0-0.293,0.116-0.575,0.323-0.782C4.025,11.477,4.307,11.36,4.6,11.36 c0.352,0,0.593,0.14,0.892,0.36L9.555,15.336z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgTickOutline;
