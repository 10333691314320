import * as React from "react";
import { SVGProps } from "react";

const SvgData = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.9,7.5C12.8,7.8,12.5,8,12.2,8h-0.6c-0.5,0-0.8,0.3-0.8,0.7v5.1c0,0.5-0.4,0.8-0.8,0.8H5.4 c-0.4,0-0.8-0.3-0.8-0.8V8.7C4.6,8.3,4.3,8,3.8,8H3.2C2.9,8,2.6,7.8,2.5,7.5s0-0.6,0.2-0.8L6.6,3 c0.377-0.335,0.752-0.452,1.1-0.455C8.048,2.548,8.423,2.665,8.8,3l3.9,3.7C12.9,6.9,13,7.2,12.9,7.5z M11.3,17.207l3.9,3.7 c0.377,0.335,0.752,0.452,1.1,0.455c0.348-0.002,0.723-0.119,1.1-0.455l3.9-3.7c0.2-0.2,0.3-0.5,0.2-0.8s-0.4-0.5-0.7-0.5h-0.6 c-0.5,0-0.8-0.3-0.8-0.7v-5.9H14c-0.4,0-0.8,0.3-0.8,0.8v5.1c0,0.4-0.3,0.7-0.8,0.7h-0.6c-0.3,0-0.6,0.2-0.7,0.5 S11.1,17.007,11.3,17.207z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgData;
