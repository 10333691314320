import * as React from "react";
import { SVGProps } from "react";

const SvgGigacube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.5,18.5v-9v-1H8.875c-0.55,0-1.25,0.375-1.375,1v11c0,0.55,0.45,1,1,1h6.462 c1.112-0.125,1.362-0.625,2.862-2.875l0.107-0.19 M20.637,15.925l0.75-1.3 M18.912,14.925l0.75-1.3"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,16.425c-0.962,1.675-2.475,2.6-3.462,2.025c-0.975-0.575-0.938-2.362,0.013-4.025L21.5,16.425z M1.462,9.587c0-3.8,3.062-7,7-7h0.012 M3.462,9.587c0-2.763,2.15-5,5-5l0,0 M5.5,9.587c0-1.663,1.338-3,3-3l0,0"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={11}
        cy={18.995}
        r={0.5}
        stroke="none"
      />
    </g>
  </svg>
);

export default SvgGigacube;
