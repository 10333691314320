import * as React from "react";
import { SVGProps } from "react";

const SvgCableError = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <circle
        vectorEffect="non-scaling-stroke"
        cx={7.5}
        cy={18.437}
        r={0.062}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.266,18.703c0.551,0.926,0.102,1.734-1,1.734H3.734c-1.098,0-1.551-0.809-1-1.734L6.5,12.137 c0.547-0.898,1.449-0.945,2,0L12.266,18.703z M7.5,14.437v2.5 M21.5,5.516h-6V14.5h6 M7,5.031c2.441,1.625,4.875,2.477,7,2.477h1.5  M15.5,12.437h-0.75c-1.613,0-3.227,0-5.578,0.875 M15.5,10.508c0,0-9.75-0.117-11.5,1.008 M15.5,9.437H14 c-1.625,0-8.875-0.656-11-2.406"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgCableError;
