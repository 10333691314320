import * as React from "react";
import { SVGProps } from "react";

const SvgConsultant = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.5,14v-4c0,0,0-7.5,8.5-7.5s8.5,7.5,8.5,7.5v4.1 M20.5,14.2c0,0,0,5.9-5.5,5.9 M3.5,11 c0-0.8,0.7-1.5,1.5-1.5l0,0c0.8,0,1.5,0.7,1.5,1.5v3c0,0.8-0.7,1.5-1.5,1.5l0,0c-0.8,0-1.5-0.7-1.5-1.5 M15,20L15,20 c0,0.8-0.7,1.5-1.5,1.5h-3C9.7,21.5,9,20.9,9,20l0,0c0-0.8,0.7-1.5,1.5-1.5h3C14.3,18.5,15,19.2,15,20z M20.5,14 c0,0.8-0.7,1.5-1.5,1.5l0,0c-0.8,0-1.5-0.7-1.5-1.5v-3c0-0.8,0.7-1.5,1.5-1.5l0,0c0.8,0,1.5,0.7,1.5,1.5"
        fill="none"
      />
    </g>
  </svg>
);

export default SvgConsultant;
