import * as React from "react";
import { SVGProps } from "react";

const SvgDarkMode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,3.6v17.2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12}
        cy={12.2}
        r={4}
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8,12.2l-4.6,0 M8.4,14H3.6 M10.3,15.8H4.2 M12,17.7H5.4 M12,19.5H7.5 M8.4,10.4H3.6 M16,12.2h-4 M15.6,14H12  M15.6,10.4H12 M10.3,8.6H4.2 M12,6.8H5.3 M12,5H7.3"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,3.6L12,3.6c4.7,0,8.6,3.9,8.6,8.6l0,0c0,4.7-3.9,8.6-8.6,8.6l0,0c-4.7,0-8.6-3.9-8.6-8.6l0,0 C3.4,7.5,7.2,3.6,12,3.6z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgDarkMode;
