import * as React from "react";
import { SVGProps } from "react";

const SvgRfidDevice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1920 2371.8"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".st0{fill:none;stroke:#000;stroke-width:5.6693;stroke-miterlimit:10}.st2{fill:#4a4d4e}.st3{fill:#fff}.st5{font-family:&apos;VodafoneExB-Regular&apos;}"
      }
    </style>
    <image
      width={428}
      height={1094}
      xlinkHref="7C95A36A.png"
      transform="matrix(.24 0 0 .24 1066.2 -7.56)"
      overflow="visible"
      opacity={0.75}
    />
    <path
      className="st0"
      d="M1094.2 11.4c-2.6-2.6-6.5-2.6-9.2 0s-2.6 6.5 0 9.2c25.5 25.5 39.9 60.2 39.9 96.2s-14.4 70.7-39.9 96.2c-2.6 2.6-2.6 6.5 0 9.2 1.3 1.3 3.3 2 4.6 2s3.3-.7 4.6-2c28.1-28.1 43.9-65.5 43.9-105.4s-15.8-77.3-43.9-105.4z"
    />
    <image
      width={406}
      height={1070}
      xlinkHref="7C95A36E.png"
      transform="matrix(.24 0 0 .24 1068.84 -4.68)"
      overflow="visible"
      opacity={0.75}
    />
    <path d="M1094.2 11.4c-2.6-2.6-6.5-2.6-9.2 0s-2.6 6.5 0 9.2c25.5 25.5 39.9 60.2 39.9 96.2s-14.4 70.7-39.9 96.2c-2.6 2.6-2.6 6.5 0 9.2 1.3 1.3 3.3 2 4.6 2s3.3-.7 4.6-2c28.1-28.1 43.9-65.5 43.9-105.4s-15.8-77.3-43.9-105.4z" />
    <image
      width={380}
      height={865}
      xlinkHref="7C95A369.png"
      transform="matrix(.24 0 0 .24 1037.88 20.04)"
      overflow="visible"
      opacity={0.75}
    />
    <path
      className="st0"
      d="M1085.7 116.8c0 26.2-9.8 50.4-28.8 68.7-2.6 2.6-2.6 6.5 0 9.2 1.3 1.3 3.3 2 4.6 2 2 0 3.3-.7 4.6-2 20.9-20.9 32.1-48.4 32.1-77.9s-11.8-57-32.1-77.9c-2.6-2.6-6.5-2.6-9.2 0s-2.6 6.5 0 9.2c19 18.3 28.8 42.5 28.8 68.7z"
    />
    <image
      width={356}
      height={842}
      xlinkHref="7C95A36B.png"
      transform="matrix(.24 0 0 .24 1040.76 22.68)"
      overflow="visible"
      opacity={0.75}
    />
    <path d="M1085.7 116.8c0 26.2-9.8 50.4-28.8 68.7-2.6 2.6-2.6 6.5 0 9.2 1.3 1.3 3.3 2 4.6 2 2 0 3.3-.7 4.6-2 20.9-20.9 32.1-48.4 32.1-77.9s-11.8-57-32.1-77.9c-2.6-2.6-6.5-2.6-9.2 0s-2.6 6.5 0 9.2c19 18.3 28.8 42.5 28.8 68.7z" />
    <image
      width={334}
      height={636}
      xlinkHref="7C95A368.png"
      transform="matrix(.24 0 0 .24 1011 47.4)"
      overflow="visible"
      opacity={0.75}
    />
    <path
      className="st0"
      d="M1030 167.2c1.3 1.3 3.3 2 4.6 2s3.3-.7 4.6-2c13.7-13.7 20.9-31.4 20.9-50.4s-7.2-36.7-20.9-50.4c-2.6-2.6-6.5-2.6-9.2 0-2.6 2.6-2.6 6.5 0 9.2 11.1 11.1 17 25.5 17 41.2s-5.9 30.1-17 41.2c-2.6 2.6-2.6 6.6 0 9.2z"
    />
    <image
      width={310}
      height={612}
      xlinkHref="7C95A376.png"
      transform="matrix(.24 0 0 .24 1013.88 50.28)"
      overflow="visible"
      opacity={0.75}
    />
    <path d="M1030 167.2c1.3 1.3 3.3 2 4.6 2s3.3-.7 4.6-2c13.7-13.7 20.9-31.4 20.9-50.4s-7.2-36.7-20.9-50.4c-2.6-2.6-6.5-2.6-9.2 0-2.6 2.6-2.6 6.5 0 9.2 11.1 11.1 17 25.5 17 41.2s-5.9 30.1-17 41.2c-2.6 2.6-2.6 6.6 0 9.2z" />
    <image
      width={429}
      height={1094}
      xlinkHref="7C95A379.png"
      transform="matrix(.24 0 0 .24 716.52 -7.56)"
      overflow="visible"
      opacity={0.75}
    />
    <path
      className="st0"
      d="M786.6 213c-25.5-25.5-39.9-60.2-39.9-96.2s14.4-70.7 39.9-96.2c2.6-2.6 2.6-6.5 0-9.2-2.6-2.6-6.5-2.6-9.2 0-28.1 28.1-43.9 65.5-43.9 105.4s15.7 77.2 43.9 105.4c1.3 1.3 3.3 2 4.6 2 2 0 3.3-.7 4.6-2 2.6-2.7 2.6-6.6 0-9.2z"
    />
    <image
      width={405}
      height={1070}
      xlinkHref="7C95A37A.png"
      transform="matrix(.24 0 0 .24 719.4 -4.68)"
      overflow="visible"
      opacity={0.75}
    />
    <path d="M786.6 213c-25.5-25.5-39.9-60.2-39.9-96.2s14.4-70.7 39.9-96.2c2.6-2.6 2.6-6.5 0-9.2-2.6-2.6-6.5-2.6-9.2 0-28.1 28.1-43.9 65.5-43.9 105.4s15.7 77.2 43.9 105.4c1.3 1.3 3.3 2 4.6 2 2 0 3.3-.7 4.6-2 2.6-2.7 2.6-6.6 0-9.2z" />
    <image
      width={380}
      height={865}
      xlinkHref="7C95A378.png"
      transform="matrix(.24 0 0 .24 755.88 20.04)"
      overflow="visible"
      opacity={0.75}
    />
    <path
      className="st0"
      d="M804.9 194.7c1.3 1.3 3.3 2 4.6 2 2 0 3.3-.7 4.6-2 2.6-2.6 2.6-6.5 0-9.2-18.3-18.3-28.8-42.5-28.8-68.7s9.8-50.4 28.8-68.7c2.6-2.6 2.6-6.5 0-9.2s-6.5-2.6-9.2 0c-20.9 20.9-32.1 48.4-32.1 77.9s11.2 56.9 32.1 77.9z"
    />
    <image
      width={356}
      height={842}
      xlinkHref="1A09EAD.png"
      transform="matrix(.24 0 0 .24 758.76 22.68)"
      overflow="visible"
      opacity={0.75}
    />
    <path d="M804.9 194.7c1.3 1.3 3.3 2 4.6 2 2 0 3.3-.7 4.6-2 2.6-2.6 2.6-6.5 0-9.2-18.3-18.3-28.8-42.5-28.8-68.7s9.8-50.4 28.8-68.7c2.6-2.6 2.6-6.5 0-9.2s-6.5-2.6-9.2 0c-20.9 20.9-32.1 48.4-32.1 77.9s11.2 56.9 32.1 77.9z" />
    <image
      width={333}
      height={636}
      xlinkHref="1A09EA3.png"
      transform="matrix(.24 0 0 .24 794.52 47.4)"
      overflow="visible"
      opacity={0.75}
    />
    <path
      className="st0"
      d="M832.4 167.2c1.3 1.3 3.3 2 4.6 2s3.3-.7 4.6-2c2.6-2.6 2.6-6.5 0-9.2-11.1-11.1-17-25.5-17-41.2s5.9-30.1 17-41.2c2.6-2.6 2.6-6.5 0-9.2-2.6-2.6-6.5-2.6-9.2 0-13.7 13.7-20.9 31.4-20.9 50.4 0 18.9 7.2 36.6 20.9 50.4z"
    />
    <image
      width={309}
      height={612}
      xlinkHref="1A09EA5.png"
      transform="matrix(.24 0 0 .24 797.4 50.28)"
      overflow="visible"
      opacity={0.75}
    />
    <path d="M832.4 167.2c1.3 1.3 3.3 2 4.6 2s3.3-.7 4.6-2c2.6-2.6 2.6-6.5 0-9.2-11.1-11.1-17-25.5-17-41.2s5.9-30.1 17-41.2c2.6-2.6 2.6-6.5 0-9.2-2.6-2.6-6.5-2.6-9.2 0-13.7 13.7-20.9 31.4-20.9 50.4 0 18.9 7.2 36.6 20.9 50.4z" />
    <path fill="#9da5ae" d="M592.9 640.1H1293.5V851.4000000000001H592.9z" />
    <path className="st2" d="M1470.4 30.3H1582.7V1957.2H1470.4z" />
    <image
      width={788}
      height={537}
      xlinkHref="../../../../../Downloads/Daco_420788.png"
      transform="matrix(0 -2.4508 .3171 0 1414.384 1961.256)"
      overflow="visible"
    />
    <image
      width={788}
      height={537}
      xlinkHref="../../../../../Downloads/Daco_420788.png"
      transform="matrix(1.3125 0 0 .05405 436.054 611.128)"
      overflow="visible"
    />
    <image
      width={788}
      height={537}
      xlinkHref="../../../../../Downloads/Daco_420788.png"
      transform="matrix(0 .3741 -.1457 0 514.306 345.368)"
      overflow="visible"
    />
    <image
      width={788}
      height={537}
      xlinkHref="../../../../../Downloads/Daco_420788.png"
      transform="matrix(0 -.5599 1.2376 0 725.002 978.281)"
      overflow="visible"
    />
    <path className="st3" d="M592.9 847.7H1463.1V1059H592.9z" />
    <path
      transform="rotate(90 1381.944 745.786)"
      className="st3"
      d="M1276.1 661H1487.6999999999998V830.5H1276.1z"
    />
    <image
      width={852}
      height={1601}
      xlinkHref="1A09EBF.png"
      transform="matrix(.24 0 0 .24 838.92 39)"
      overflow="visible"
      opacity={0.75}
    />
    <path
      className="st0"
      d="M1001.2 368.3h-55.9V174.8C970.8 169.3 990 145 990 116c0-33.1-25.1-60.1-55.9-60.1-30.8 0-55.9 26.9-55.9 60.1 0 29 19.2 53.3 44.7 58.8v193.5H867c-6.2 0-11.2 5.4-11.2 12s5 12 11.2 12h134.2c6.2 0 11.2-5.4 11.2-12s-5-12-11.2-12zM900.6 116c0-19.9 15.1-36 33.6-36s33.6 16.2 33.6 36-15.1 36-33.6 36-33.6-16.1-33.6-36z"
    />
    <g>
      <image
        width={828}
        height={1577}
        xlinkHref="1A09EBD.png"
        transform="matrix(.24 0 0 .24 841.8 41.88)"
        overflow="visible"
        opacity={0.75}
      />
      <path d="M1001.2 368.3h-55.9V174.8C970.8 169.3 990 145 990 116c0-33.1-25.1-60.1-55.9-60.1-30.8 0-55.9 26.9-55.9 60.1 0 29 19.2 53.3 44.7 58.8v193.5H867c-6.2 0-11.2 5.4-11.2 12s5 12 11.2 12h134.2c6.2 0 11.2-5.4 11.2-12s-5-12-11.2-12zM900.6 116c0-19.9 15.1-36 33.6-36s33.6 16.2 33.6 36-15.1 36-33.6 36-33.6-16.1-33.6-36z" />
    </g>
    <image
      width={788}
      height={537}
      xlinkHref="../../../../../Downloads/Daco_420788.png"
      transform="matrix(0 .5599 -1.5171 0 1255.554 537.083)"
      overflow="visible"
    />
    <path d="M436.1 345.4H1470.4V638.0999999999999H436.1z" />
    <text
      transform="translate(979.661 576.19)"
      className="st5"
      fontSize="222.8237px"
      fill="#a2000d"
    >
      {"RFID"}
    </text>
    <path fill="#25282b" d="M436.1 345.4H1470.4V638.0999999999999H436.1z" />
    <text
      transform="translate(900.554 588.445)"
      className="st5"
      fontSize="275.8622px"
      fill="#e60000"
    >
      {"RFID"}
    </text>
    <image
      width={788}
      height={537}
      xlinkHref="../../../../../Downloads/Daco_420788.png"
      transform="matrix(0 -.3714 .3293 0 1293.48 638.053)"
      overflow="visible"
    />
    <image
      width={788}
      height={537}
      xlinkHref="../../../../../Downloads/Daco_420788.png"
      transform="matrix(-1.3038 0 0 -.0677 1466.191 381.726)"
      overflow="visible"
    />
    <path className="st3" d="M440.8 640.2H590.9V851.5H440.8z" />
    <path className="st3" d="M438.8 847.7H1352.6V1059H438.8z" />
    <image
      width={788}
      height={537}
      xlinkHref="../../../../../Downloads/Daco_420788.png"
      transform="matrix(0 .3714 -.2654 0 577.68 346.418)"
      overflow="visible"
    />
    <path className="st2" d="M322.9 30.3H435.2V1961.3H322.9z" />
    <image
      width={788}
      height={537}
      xlinkHref="../../../../../Downloads/Daco_420788.png"
      transform="matrix(0 2.4605 -.3206 0 495.113 22.363)"
      overflow="visible"
    />
  </svg>
);

export default SvgRfidDevice;
