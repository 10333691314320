import * as S from "./RectangularButton.styles";
import React, { createRef, FC, useContext } from "react";
import { useRipple } from "react-use-ripple";
import { ThemeContext } from "styled-components";
import { RectangularButtonProps } from "./RectangularButton.types";
import { Icon } from "../Icon";

export const RectangularButton: FC<RectangularButtonProps> = ({
  as,
  label,
  icon,
  iconPosition = "Start",
  url,
  target,
  variant,
  onClick,
  disabled,
  type,
  fullWidth,
  trackingData,
  children,
  ...props
}: RectangularButtonProps) => {
  const ref = createRef<HTMLAnchorElement>();
  const theme = useContext(ThemeContext);

  useRipple(ref, {
    rippleColor:
      variant === ("tertiary-opt1" || "tertiary-opt2")
        ? theme.color.monochrome500
        : theme.color.primary200,
  });

  return (
    <S.RectangularButton
      as={as}
      href={url}
      target={target}
      iconPosition={iconPosition}
      rel={target === "_blank" ? "noopener" : undefined}
      variant={variant}
      ref={ref}
      onClick={onClick}
      disabled={disabled}
      type={type}
      icon={icon}
      fullWidth={fullWidth}
      data-tracking-data={trackingData}
      {...props}
    >
      <S.ButtonContent>
        {icon?.as && iconPosition === "Start" && <Icon as={icon?.as} />}
        <span dangerouslySetInnerHTML={{ __html: unescape(label) }} />
        {children}
        {icon?.as && iconPosition === "End" && <Icon as={icon?.as} />}
      </S.ButtonContent>
    </S.RectangularButton>
  );
};
