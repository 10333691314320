import * as React from "react";
import { SVGProps } from "react";

const SvgNetworkTower = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.4,5.2C6.2,6.5,5.5,8.1,5.5,9.9s0.7,3.4,1.9,4.6 M5.2,3.3 C3.5,5,2.5,7.4,2.5,9.9s1,4.8,2.7,6.5 M16.6,5.2c1.2,1.2,1.9,2.9,1.9,4.6s-0.7,3.4-1.9,4.6 M18.8,3.3c1.7,1.7,2.7,4.1,2.7,6.5 s-1,4.8-2.7,6.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14,16.6l-5.2,4.9c0,0-0.1,0-0.2,0s-0.1-0.1-0.1-0.1l2.9-10.1 M12.5,11.3 l2.9,10.1c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0-0.2,0l-5.2-5 M12,8h1.7v1.7c0,0.9-0.8,1.7-1.7,1.7l0,0c-0.9,0-1.7-0.8-1.7-1.7l0,0 C10.3,8.8,11.1,8,12,8z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgNetworkTower;
