import * as React from "react";
import { SVGProps } from "react";

const SvgRedox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,3.4c-3,3.3-5.9,7.8-5.9,11c0,3.4,2.5,6.2,5.9,6.2s5.9-2.7,5.9-6.1C17.9,9.9,12,3.4,12,3.4"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        vectorEffect="non-scaling-stroke"
        x1={9.7}
        y1={12.5}
        x2={9.7}
        y2={15.2}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        vectorEffect="non-scaling-stroke"
        x1={8.4}
        y1={13.8}
        x2={11.1}
        y2={13.8}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        vectorEffect="non-scaling-stroke"
        x1={12.9}
        y1={13.8}
        x2={15.6}
        y2={13.8}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgRedox;
