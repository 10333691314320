import * as React from "react";
import { SVGProps } from "react";

const SvgSavings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.5,11.1c-1.1,0.3-1.9,0.8-1.9,1.3c0,0.9,3,1.6,5.5,1.6c2.2,0,5.1-0.5,5.4-1.3v-0.1l0,0v3.3 c-0.6,0.7-3.4,1-5.5,1s-4.8-0.5-5.4-1.2v-3.3 M16.5,14.6c0.6,0.3,1,0.6,1,1 M17.5,15.6V19c-0.6,0.7-3.4,1-5.5,1s-4.9-0.5-5.5-1.2 v-2.5 M12,3.9c3,0,5.5,0.7,5.5,1.6S15,7.1,12,7.1S6.5,6.4,6.5,5.5S9,3.9,12,3.9z M17.5,5.5V9c-0.6,0.7-3.4,1-5.5,1S7.1,9.5,6.5,8.8 V5.5 M17.5,7.9c0.6,0.3,1,0.6,1,1 M18.5,8.9v3c-0.6,0.7-3.4,1-5.5,1s-4.9-0.5-5.5-1.2V9.4 M7.6,11.1"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgSavings;
