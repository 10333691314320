import * as React from "react";
import { SVGProps } from "react";

const SvgStandard4G = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M10,8.964v6.25 M11,13.464H6.75l3.25-4.5 M15.25,12.206H17v1.5c0.017,0.55-0.344,1.041-0.875,1.188 C15.09,15.176,13,15.36,13,11.956c0-3.688,3.32-3.236,3.812-2.312"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,12c0,5.247-4.253,9.5-9.5,9.5S2.5,17.247,2.5,12S6.753,2.5,12,2.5h9.5V12z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgStandard4G;
