import * as React from "react";
import { SVGProps } from "react";

const SvgPadlockClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.988,15.426v2.031"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.41,14.016c0,0.781-0.633,1.41-1.41,1.41s-1.41-0.629-1.41-1.41c0-0.777,0.633-1.406,1.41-1.406 S13.41,13.238,13.41,14.016z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.5,9.473V6.059c0-1.938-1.574-3.512-3.512-3.512c-1.941,0-3.512,1.574-3.512,3.512v3.414"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M19.516,9.473H6.48c-1.105,0-2,0.895-2,2v7.98c0,1.105,0.895,2,2,2H17.52c1.105,0,2-0.895,2-2L19.516,9.473z"
        fill="none"
        strokeLinecap="square"
      />
    </g>
  </svg>
);

export default SvgPadlockClose;
