import * as React from "react";
import { SVGProps } from "react";

const SvgTrackisafe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,3.3c3.8,0,6.9,3.1,6.9,6.9l0,0c0,4.6-6.9,10.5-6.9,10.5 s-6.9-6-6.9-10.5C5.1,6.4,8.2,3.3,12,3.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.3,14c0-0.2,0.1-0.3,0.3-0.3s0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3S9.3,14.2,9.3,14z M12,14.3 c0.2,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3s-0.3,0.1-0.3,0.3S11.8,14.3,12,14.3z M14.4,14.3c0.2,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3 s-0.3,0.1-0.3,0.3S14.2,14.3,14.4,14.3z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgTrackisafe;
