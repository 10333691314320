import styled from "styled-components";
import { spacing, fontSize } from "../../../theme";
import { deviceUp } from "../Grid";

export const Label = styled.div`
  display: block;
  margin-bottom: ${spacing("xs")};
`;

export const Picker = styled.div`
  margin-top: ${spacing("m")};

  .react-datepicker-wrapper {
    display: inline-block;
    margin-right: ${spacing("l")};
  }

  .react-datepicker__input-container input {
    height: 48px;
    border-radius: 6px;
    border: solid 1px ${({ theme }) => theme.color.monochrome400};
    padding: ${spacing("s")};
    width: 100%;
    background-color: transparent;
    color: ${({ theme }) => theme.color.monochrome600};

    &:focus,
    &:focus-visible,
    .js-focus-visible &.focus-visible {
      outline: solid 1px ${({ theme }) => theme.color.state500};
      border: solid 1px ${({ theme }) => theme.color.state500};
    }

    @media ${deviceUp.m} {
      height: 48px;
      padding: ${spacing("m")};
    }
  }

  .react-datepicker__aria-live {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
  }

  .react-datepicker__tab-loop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .react-datepicker-popper {
    z-index: 1;
  }

  .react-datepicker-popper[data-placement^="top"] {
    padding-bottom: 10px;
  }

  .react-datepicker {
    background-color: ${({ theme }) => theme.color.bgPanel100};
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.32);
    padding: 0;
    border-radius: 6px;
    display: inline-block;
    position: relative;
  }

  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    margin-left: -4px;
    position: absolute;
    width: 0;
  }
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    left: -8px;
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: ${({ theme }) => theme.color.monochrome200};
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    top: 0;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    top: -1px;
  }

  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
    bottom: 0;
    margin-bottom: -8px;
  }
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::after {
    border-bottom: none;
    border-top-color: ${({ theme }) => theme.color.bgPanel100};
  }
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::after {
    bottom: 0;
  }
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before {
    bottom: -1px;
  }

  .react-datepicker__triangle {
    position: absolute;
    left: 50px;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 10px;
  }
  .react-datepicker-popper[data-placement="bottom-end"]
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement="top-end"]
    .react-datepicker__triangle {
    left: auto;
    right: 50px;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: ${({ theme }) => theme.color.bgPanel200};
    border-bottom: 1px solid ${({ theme }) => theme.color.monochrome300};
    padding: ${spacing("xs", 0)};
    position: relative;
  }

  .react-datepicker__navigation--previous {
    left: 2px;
  }
  .react-datepicker__navigation--next {
    right: 2px;
  }

  .react-datepicker__navigation-icon--previous {
    right: -2px;
  }
  .react-datepicker__navigation-icon--previous::before {
    transform: rotate(225deg);
    right: -7px;
  }

  .react-datepicker__navigation-icon--next {
    left: -2px;
  }
  .react-datepicker__navigation-icon--next::before {
    transform: rotate(45deg);
    left: -7px;
  }

  .react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 36px;
    width: 36px;
    text-indent: -999em;
    overflow: hidden;
  }

  .react-datepicker__navigation:hover *::before {
    border-color: ${({ theme }) => theme.color.monochrome500};
  }

  .react-datepicker__navigation-icon {
    position: relative;
    font-size: 20px;
    width: 0;
  }

  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.color.monochrome400};
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: "";
    display: block;
    height: 10px;
    position: absolute;
    top: 8px;
    width: 10px;
  }

  .react-datepicker__current-month {
    margin-top: 0;
    color: ${({ theme }) => theme.color.monochrome500};
    ${fontSize("xxs", "h5")};

    @media ${deviceUp.m} {
      ${fontSize("m", "h5")};
    }
  }

  .react-datepicker__month-container {
    float: left;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    white-space: nowrap;
  }

  .react-datepicker__day-names {
    margin-bottom: -8px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    color: ${({ theme }) => theme.color.monochrome500};
    display: inline-block;
    width: 20px;
    ${fontSize("xxs", "badge")};
    margin: ${spacing("xxs")};
    line-height: 20px;

    @media ${deviceUp.m} {
      ${fontSize("m", "badge")};
      width: 28px;
      line-height: 28px;
    }
  }

  .react-datepicker__month {
    padding: ${spacing("m")};
    text-align: center;
  }

  .react-datepicker__day {
    cursor: pointer;
  }
  .react-datepicker__day:hover {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.monochrome200};
  }
  .react-datepicker__day--today {
    font-weight: bold;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.secondary100};
    color: ${({ theme }) => theme.color.monochrome100};
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover {
    background-color: ${({ theme }) => theme.color.state500};
  }
  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    background-color: ${({ theme }) => theme.color.state500};
  }
  .react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(
      .react-datepicker__day--in-selecting-range
    ),
  .react-datepicker__month--selecting-range
    .react-datepicker__month-text--in-range:not(
      .react-datepicker__day--in-selecting-range
    ),
  .react-datepicker__month--selecting-range
    .react-datepicker__quarter-text--in-range:not(
      .react-datepicker__day--in-selecting-range
    ),
  .react-datepicker__month--selecting-range
    .react-datepicker__year-text--in-range:not(
      .react-datepicker__day--in-selecting-range
    ) {
    background-color: ${({ theme }) => theme.color.monochrome200};
    color: ${({ theme }) => theme.color.monochrome500};
  }
`;
