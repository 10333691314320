import * as React from "react";
import { SVGProps } from "react";

const SvgInfoWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#FFFFFF" fill="#FFFFFF">
      <path
        d="M20.5,3.5c4.7,4.7,4.7,12.3,0,17c-4.7,4.7-12.3,4.7-17,0c-4.7-4.7-4.7-12.3,0-17C8.2-1.2,15.8-1.2,20.5,3.5z  M12,9.5h-1.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7l0,0h0.7l0,6.7l-0.7,0c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7l0,0h2.7 c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7l0,0l-0.7,0l0-7.3C12.7,9.8,12.4,9.5,12,9.5L12,9.5z M11.7,5.3c-0.6,0-1,0.4-1,1s0.4,1,1,1 c0.6,0,1-0.4,1-1S12.2,5.3,11.7,5.3z"
        stroke="none"
      />
    </g>
  </svg>
);

export default SvgInfoWhite;
