import React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../../../config/config";
import * as S from "./SignInMSAL.styles";
import { Icon } from "../Icon";
import { Headline } from "../Headline";

export const SignInMSAL = () => {
  const { inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in.
  // Processing the server response takes a render cycle or two
  if (
    isAuthenticated ||
    inProgress === InteractionStatus.Startup ||
    inProgress === InteractionStatus.HandleRedirect
  ) {
    return null;
  }

  return (
    <S.LoginWrapper>
      <S.VfIcon forwardedAs={"VodafoneLogo"} iconPackage={"brand"} />
      <Headline as={"h1"} bold text="Vodafone IoT Dashboard" />
      <button onClick={() => handleLogin()}>
        <S.IconWrapper>
          <Icon as={"LogIn"} />
        </S.IconWrapper>
        Sign in
      </button>
    </S.LoginWrapper>
  );
};
