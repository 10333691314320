import * as React from "react";
import { SVGProps } from "react";

const SvgScanCreditCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.5,3.5h-3v3 M3.5,17.5v3h3 M17.5,20.5h3v-3 M5.504,10.273h12.992"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.5,6.5v-3h-3 M6.875,7.547H18.5v7.539c0,0.363-0.145,0.711-0.402,0.969s-0.609,0.398-0.973,0.398H6.875 c-0.363,0-0.715-0.141-0.973-0.398S5.5,15.449,5.5,15.086V8.914c0-0.363,0.145-0.711,0.402-0.969S6.512,7.547,6.875,7.547z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgScanCreditCard;
