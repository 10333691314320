import * as React from "react";
import { SVGProps } from "react";

const SvgShoppingCheckout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.438,9.5h13.086v9.984c0,0.531-0.215,1.043-0.594,1.418s-0.895,0.582-1.426,0.582H6.438 c-0.535,0-1.047-0.207-1.426-0.582s-0.594-0.887-0.598-1.418V11.5c0.004-0.535,0.219-1.043,0.598-1.418S5.902,9.496,6.438,9.5z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.449,9.5V7c0-2.48,2.035-4.492,4.551-4.492S16.551,4.52,16.551,7v2.5"
        fill="none"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.953,12c0-0.277-0.227-0.5-0.504-0.5c-0.281,0-0.508,0.223-0.508,0.5s0.227,0.5,0.508,0.5 C7.727,12.5,7.953,12.277,7.953,12z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.059,12c0-0.277-0.227-0.5-0.508-0.5c-0.277,0-0.504,0.223-0.504,0.5s0.227,0.5,0.504,0.5 C16.832,12.5,17.059,12.277,17.059,12z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgShoppingCheckout;
