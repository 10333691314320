import * as React from "react";
import { SVGProps } from "react";

const SvgVideoFrame = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.324,11.715L11,9.605c-0.102-0.062-0.23-0.066-0.336-0.008s-0.172,0.172-0.172,0.293v4.293 c0,0.121,0.066,0.23,0.172,0.289s0.234,0.055,0.336-0.008l3.328-2.184c0.098-0.059,0.156-0.168,0.156-0.285 C14.484,11.883,14.422,11.773,14.324,11.715z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.008,4.586v14.926 M6,4.586v14.926 M18.008,7.531h2.477 M18.008,10.531h2.477 M18.008,13.531h2.477 M18.008,16.531h2.477 M3.531,7.531h2.477 M3.531,10.531h2.477 M3.531,13.531h2.477 M3.531,16.531h2.477"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.531,4.586h14.953v12.926c0,1.105-0.895,2-2,2H5.531c-1.105,0-2-0.895-2-2V6.586 C3.531,5.484,4.426,4.586,5.531,4.586z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgVideoFrame;
