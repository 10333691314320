import React, { FC, MouseEvent } from "react";
import * as S from "./FilterTab.styles";
import { Icon, IconProps } from "../Icon";

export type FilterTabProps = {
  label: string | null;
  active?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  icon: IconProps;
};

export const FilterTab: FC<FilterTabProps> = ({
  label,
  active,
  onClick,
  icon,
}) => {
  return (
    <S.FilterTab as={"button"} active={active} onClick={onClick}>
      <Icon as={icon?.as} iconPackage={icon?.iconPackage} />
      <span>{label}</span>
    </S.FilterTab>
  );
};
