import * as React from "react";
import { SVGProps } from "react";

const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.1,13.9l-1-0.6c-0.8-0.5-1-1.5-0.5-2.2c0.1-0.2,0.3-0.4,0.5-0.5l1-0.6c0.8-0.5,1-1.4,0.6-2.1l-0.8-1.3 c-0.5-0.8-1.4-1-2.1-0.6l-0.9,0.6c-1,0.6-2.4-0.2-2.4-1.3V4.1c0-0.9-0.7-1.6-1.6-1.6h-1.6c-0.9,0-1.6,0.7-1.6,1.6v1 c0,1.2-1.3,2-2.4,1.3L6.3,6C5.6,5.5,4.6,5.8,4.2,6.5c0,0,0,0,0,0L3.4,7.9C3,8.7,3.2,9.6,3.9,10.1l1,0.6c1,0.6,1,2.1,0,2.7l-1,0.6 c-0.8,0.5-1,1.4-0.6,2.1l0.8,1.3c0.5,0.8,1.4,1,2.1,0.6l0.9-0.5c1-0.6,2.4,0.2,2.4,1.3v1.1c0,0.9,0.7,1.6,1.6,1.6h1.6 c0.9,0,1.6-0.7,1.6-1.6v-1c0-1.2,1.3-2,2.4-1.3l1,0.6c0.7,0.4,1.7,0.2,2.1-0.5c0,0,0,0,0,0l0.8-1.3C21,15.3,20.8,14.4,20.1,13.9z  M12,15.2c-1.7,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2c1.7,0,3.2,1.4,3.2,3.2C15.2,13.7,13.7,15.2,12,15.2z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgSettings;
