import * as React from "react";
import { SVGProps } from "react";

const SvgXingBoxed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="#fff">
      <path d="M18.41,8.34C19.71,6.07,21,3.8,22.3,1.47c.22-.37.16-.54-.16-.81H18.57a1.3,1.3,0,0,0-.7.6C17.11,2.61,16.3,4,15.55,5.37S13.82,8.45,13,10c-.7,1.24-1.4,2.49-2.11,3.68a.36.36,0,0,0,0,.32c.38.65.71,1.24,1,1.89,1.08,2,2.22,4,3.3,6a1.4,1.4,0,0,0,.86.81H19.6a.51.51,0,0,0,.22-.75c-.49-.87-1-1.73-1.46-2.65-1-1.79-1.95-3.52-2.92-5.3a.32.32,0,0,1,0-.43C16.41,11.85,17.44,10.07,18.41,8.34ZM10,9.31a5.38,5.38,0,0,0-.43-.76c-.54-1-1.13-1.94-1.67-2.91A1.22,1.22,0,0,0,6.79,5H3.71a.41.41,0,0,0-.38.59c0,.06,0,.16.11.22.65,1.13,1.3,2.27,2,3.4,0,.17.11.27,0,.38-.22.38-.43.76-.65,1.08L2.2,15.26c-.27.43-.06.81.48.81H5.55a1.18,1.18,0,0,0,1.19-.7c.43-.76.86-1.46,1.24-2.22.7-1.19,1.4-2.43,2-3.68C10.09,9.47,10.09,9.37,10,9.31Z" />
    </g>
  </svg>
);

export default SvgXingBoxed;
