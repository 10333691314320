import * as React from "react";
import { SVGProps } from "react";

const SvgRecyclingCube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 640"
    xmlSpace="preserve"
    enableBackground="new 0 0 512 640"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M253,66L33,193v254l220,127l220-127V193L253,66z M448.5,197.7L253,310.6L57.5,197.7L253,84.8L448.5,197.7z
        M49.3,211.8l195.5,112.9v225.8L49.3,437.6V211.8z M261.2,550.5V324.7l195.5-112.9v225.8L261.2,550.5z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M322.2,379.5c-2.3,0.8-3.1-1.4-8.6,0.3c-2.1,0.5-3.1-0.5-3.6-0.9c-4.1-4.9,3.8-25.9,3.8-25.9
        c7.3-15.6,16.7-19.8,19.8-22.6c1.9-1.7,0.9-2.6-0.4-6.3c-1.1-2.8,1.5-5.4,4.3-4.8c38,7.9,22.5,6,12.7,26.7
        c-6.5,13.4-8.7-1.8-10.3-2.6c0,0-0.5,0.5-1.7,1.6c-2.5,2.9-4.7,6.3-7.3,9.5C321.9,367.7,326.7,377.8,322.2,379.5L322.2,379.5z
        M336.3,323.1c1.8,5.7,3,7.2-2.6,11c-3.9,2.4-11,7.4-17.2,20c-1.4,3.1-6.2,20.3-4.3,22.8c0.5,0.5,2-1.4,8.3-0.2
        c0.4,0.2,0.4,0.2,0.7,0.1c1.4-1.5-0.7-12,7.5-23.7c11.3-16.2,12.5-15.3,16.3-6.2c0.3,0.8,0.4,1.1,0.5,1.4c1.8-2,2.9-8.5,13.1-19.7
        c1.1-1.4-0.7-2-21.4-6.4C336.8,321.9,336.1,322.5,336.3,323.1z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M335.2,437.9c-23.1-10.9-19.3-23-26.3-20.3l-1.1,0.4c-1.7,0.6-3.1,1.2-5-0.4c-1-0.9-1.5-2.3-1.3-3.4
        c1.4-7.3,3-14.3,5.1-21.5c0.7-2.5,4.1-3.5,6.1-1.7c6.2,5.4,12,8.7,15.6,10.5c3.7,2.1,5.6,3,5.4,5c-0.1,3.2-7.3,3.7-8.4,5.1
        c0.4,1.1,7.9,6.4,11.4,8.9c12.7,9,22,4.6,24.6,8.1c1.5,2.3-0.9,3.6,0.5,9.1C364.8,447.4,341.4,440.8,335.2,437.9z M307.9,414.8
        c11-4.1,4.7,9.1,28.9,20.3c3.9,1.8,23.5,6.9,22.6,3.7c-1.1-3.8-0.7-4.5-0.2-8.3c-1.1-1.2-12,0.7-23.7-7.5
        c-10.5-7.3-12.9-9.6-12.6-12c0.3-2.7,5.1-3.5,7.8-4.9c-3-2.1-10.6-5-19.7-13.1c-1.4-1.1-2,0.7-6.2,21c0.1,0.3-0.4,0.8,0.1,1.2
        s1,0,1.8-0.4L307.9,414.8zM3.4,5.6L4,9.2l3.6-0.6 M4,9.2c2.8-5.1,7.6-6.5,11.7-4.5c2.5,1.2,4.2,3.7,4.5,6.4 M20.6,18.4L20,14.8l-3.6,0.6  M12,10.5c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5S11.2,10.5,12,10.5z M20,14.8c-2.9,5.2-7.7,6.5-11.7,4.5 c-2.2-1.1-3.8-3.1-4.3-5.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M396.6,449.8l-4.5-0.9c-29.3-6-19-6-9.1-23.3c1.8-2.9,2.9-6.9,5.1-7.7c2.3-0.8,3.4,1.3,4.2,3.5
        c0.6,1.7,1.6,4.2,2.1,4.7c0,0,0.5-0.5,1.7-1.6c9.9-12.1,13.1-18.1,13.9-31.3c0.1-2.3,2.3-4.1,4.4-3.6c2.8,0.6,3.2,0.7,6.4-0.2
        c2.1-0.5,3.1,0.5,3.6,0.9c4.1,4.9-3.8,25.9-3.8,25.9c-7.3,15.6-16.7,19.8-19.8,22.6c-1.9,1.7-0.9,2.6,0.4,6.3
        C401.7,447,399.4,450.4,396.6,449.8z M388.9,420.9c-1.8,2-1.9,5.2-10.3,16.1c-4.1,5.4-6.6,4.7,14,8.9c5.1,0.7,6.7,2.3,5.2-1.6
        c-4.5-11.8,8.8-4.9,20.2-29.2c1.8-3.9,6.9-23.5,3.7-22.6c-6.7,1.9-8.5-1.3-8.7,0.7c-0.6,13.7-4.4,20.6-14.6,32.9
        c-2.5,2.9-4.4,2.9-5.1,2.6c-1.6-0.7-2.2-2.4-3-4.6c-0.2-0.6-0.4-1.1-0.5-1.4C389.5,421.6,389.1,421.2,388.9,420.9z.4,5.6L4,9.2l3.6-0.6 M4,9.2c2.8-5.1,7.6-6.5,11.7-4.5c2.5,1.2,4.2,3.7,4.5,6.4 M20.6,18.4L20,14.8l-3.6,0.6  M12,10.5c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5S11.2,10.5,12,10.5z M20,14.8c-2.9,5.2-7.7,6.5-11.7,4.5 c-2.2-1.1-3.8-3.1-4.3-5.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M408.4,368.5c-3.3-2-8-3.4-8.1-6.3c0.2-3.6,7.2-4,8.3-5.4c-0.4-1.1-7.9-6.4-11.4-8.9
        c-14.4-10-26.6-3.2-25.1-11.1c0.6-2.8,0.7-3.2-0.2-6.4c-2.4-9,21-2.4,27.2,0.4c23.1,10.9,19,23.1,26.3,20.3l1.1-0.4
        c0.3-0.1,0.8-0.3,1.1-0.4c1.1-0.4,2.6,0,3.6,0.9c1,0.9,1.5,2.3,1.3,3.4l-1,4.9C425.5,389.1,425.5,378.8,408.4,368.5z M403.6,362.6
        c2,1.8,5.5,1.8,16.1,10.3c5.6,4.6,4.7,6.6,9.9-18.9c0.2-0.4-0.2-0.6-0.2-0.6s-0.4-0.2-0.7-0.1c-15.1,5.1-5.4-7.6-30.9-19.6
        c-3.9-1.8-23.5-6.9-22.6-3.7c1.1,3.8,0.7,4.5,0.2,8.3c1.1,1.2,12-0.7,23.7,7.5c11.3,7.7,17.9,11.6,7.6,15.8l-1.4,0.5
        C404.2,362.4,403.9,362.5,403.6,362.6z.4,5.6L4,9.2l3.6-0.6 M4,9.2c2.8-5.1,7.6-6.5,11.7-4.5c2.5,1.2,4.2,3.7,4.5,6.4 M20.6,18.4L20,14.8l-3.6,0.6  M12,10.5c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5S11.2,10.5,12,10.5z M20,14.8c-2.9,5.2-7.7,6.5-11.7,4.5 c-2.2-1.1-3.8-3.1-4.3-5.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgRecyclingCube;
