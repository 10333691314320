import React, { FC } from "react";
import { Col, Row } from "../Grid";
import * as S from "./HeadlineContainer.styles";
import { HeadlineContainerProps } from "./HeadlineContainer.types";

export const HeadlineContainer: FC<HeadlineContainerProps> = ({
  marginTop = true,
  marginBottom = true,
  color = "monochrome500",
  children,
}) => {
  return (
    <S.HeadlineContainer
      marginTop={marginTop}
      marginBottom={marginBottom}
      color={color}
    >
      <Row middle={"xs"} center={"xs"}>
        <Col>{children}</Col>
      </Row>
    </S.HeadlineContainer>
  );
};
