import * as React from "react";
import { SVGProps } from "react";

const SvgNumber7 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,12.027c-0.016,5.242-4.258,9.484-9.5,9.5c-5.242-0.016-9.484-4.258-9.5-9.5 c0.016-5.238,4.258-9.484,9.5-9.5h9.5V12.027z"
        fill="none"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.188,16.5l0.047-0.32c0.406-2.816,1.473-5.496,3.105-7.828L14.938,7.5H9.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgNumber7;
