import styled from "styled-components";
import { deviceUp } from "../Grid";

export const CircularBackButton = styled.button`
  border: none;
  padding: 0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.primary100};
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.color.secondary700};
  }

  @media ${deviceUp.m} {
    width: 40px;
    height: 40px;
  }

  svg {
    width: 20px;
    height: 20px;

    @media ${deviceUp.m} {
      width: 24px;
      height: 24px;
    }

    g {
      fill: ${({ theme }) => theme.color.primary200};
      stroke: ${({ theme }) => theme.color.primary200};
    }
  }
`;
