import * as React from "react";
import { SVGProps } from "react";

const SvgAccelerometer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,4.6v1.5 M17.3,6.7L12,12 M19.4,12H18 M6.7,6.8l1,1 M4.6,12H6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,12c0,5.2-4.3,9.5-9.5,9.5S2.5,17.2,2.5,12S6.8,2.5,12,2.5h9.5V12z"
        fill="none"
        strokeLinecap="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12}
        cy={12}
        r={1}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgAccelerometer;
