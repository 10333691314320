import React, { FC } from "react";
import * as S from "./ProjectCard.styles";
import { ProjectCardProps } from "./ProjectCard.types";
import { RectangularButton } from "../RectangularButton";

export const ProjectCard: FC<ProjectCardProps> = ({
  name,
  path,
  image,
  subline,
}) => {
  return (
    <S.ProjectCardWrapper xs={12} md={6} lg={3}>
      <S.ProjectCard as={"a"} href={path}>
        <S.TopContainer>
          <picture>
            <img src={image} alt="Project" />
          </picture>
          <S.ProjectCardHeadline>{name}</S.ProjectCardHeadline>
          <S.Subline>{subline}</S.Subline>
        </S.TopContainer>
        <S.BottomWrapper>
          <RectangularButton
            label={"Dashboard"}
            variant="primary"
            url={path}
            icon={{ as: "ChevronRight" }}
            iconPosition={"End"}
          />
        </S.BottomWrapper>
      </S.ProjectCard>
    </S.ProjectCardWrapper>
  );
};
