import * as React from "react";
import { SVGProps } from "react";

const SvgOnlineForm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.973,4.227h14.055v8.41c0,1.102-0.898,2-2,2H5.973c-1.102,0-2-0.898-2-2v-6.41 C3.973,5.121,4.871,4.227,5.973,4.227z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.449,14.637c0.668,0,1.691,0.184,2.094,1.27c0.398,1.09,0.711,1.891,0.887,2.559 c0.18,0.668-0.086,1.312-1.867,1.312H4.438c-1.781,0-2.047-0.645-1.867-1.312c0.176-0.668,0.488-1.469,0.887-2.559 c0.402-1.09,1.426-1.27,2.094-1.27H18.449z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.523,17.238h10.953 M17.559,7.785l-3.543,3.543l-1.574-1.574 M6,7.43h4 M6,9.43h4 M6,11.43h4"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgOnlineForm;
