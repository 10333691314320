import * as React from "react";
import { SVGProps } from "react";

const SvgEsim = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.48,4.5h13v13c0,1.105-0.895,2-2,2h-11c-1.105,0-2-0.895-2-2v-11C4.48,5.395,5.375,4.5,6.48,4.5z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.097,7.5h5.382v9h-9v-5.4L11.097,7.5z M7.48,4.5v-2 M10.48,4.5v-2 M13.48,4.5v-2 M16.48,4.5v-2 M7.48,21.5 v-2 M10.48,21.5v-2 M13.48,21.5v-2 M16.48,21.5v-2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgEsim;
