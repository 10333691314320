import * as React from "react";
import { SVGProps } from "react";

const SvgSync = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.5,21.445v-4.762h4.727 M18.5,2.578v4.75h-4.727 M3.961,13.387c-0.543-3.68,1.48-7.254,4.914-8.684 c3.43-1.43,7.395-0.348,9.625,2.625 M20.039,10.625c0.543,3.68-1.48,7.254-4.914,8.684c-3.43,1.43-7.395,0.348-9.625-2.625"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgSync;
