import React, { FC } from "react";
import * as S from "./DataLine.styles";
import { DataLineProps } from "./DataLine.types";
import { Icon } from "../Icon";

export const DataLine: FC<DataLineProps> = ({ icon, value, children }) => {
  return (
    <S.DataLine>
      <Icon as={icon} />
      {value}
      {children}
    </S.DataLine>
  );
};
