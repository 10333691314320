import * as React from "react";
import { SVGProps } from "react";

const SvgWalkieTalkie = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 64 80"
    xmlSpace="preserve"
    enableBackground="new 0 0 64 80"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="m34.75,31.69h-16.4c-.44,0-.8.36-.8.8v10.87c0,.44.36.8.8.8h16.4c.44,0,.8-.36.8-.8v-10.87c0-.44-.36-.8-.8-.8Zm-.8,10.86h-14.8v-9.27h14.81v9.27h0Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="m37.77,28.2V10.41c0-1.66-1.35-3.02-3.02-3.02s-3.03,1.35-3.03,3.02v17.2h-2.9v-3.13c0-1.38-1.13-2.51-2.52-2.51s-2.52,1.13-2.52,2.51v3.13h-2.91v-3.13c0-1.38-1.13-2.51-2.52-2.51s-2.52,1.13-2.52,2.51v3.47c-1.69.69-2.89,2.35-2.89,4.28v11.37c0,1.1.4,2.17,1.12,3.02.71.82,1.56,2.14,1.56,3.74v16.47c0,2.74,2.32,4.96,5.16,4.96h11.55c2.83,0,5.15-2.23,5.16-5.01v-16.42c0-1.6.85-2.91,1.56-3.74.73-.85,1.12-1.92,1.12-3.02v-11.36c0-1.74-.97-3.24-2.4-4.04Zm-4.45-17.79c0-.77.66-1.42,1.44-1.42s1.42.65,1.42,1.42v17.26c-.21-.03-.42-.06-.63-.06h-2.22V10.41h0Zm-7.95,14.07c0-.5.41-.91.93-.91s.93.42.93.91v3.13h-1.85v-3.13h-.01Zm-7.95,0c0-.49.42-.91.93-.91s.92.42.92.91v3.13h-1.71s-.09,0-.14,0v-3.14Zm21.16,19.12c0,.72-.26,1.42-.74,1.98-1.27,1.48-1.95,3.13-1.95,4.78v16.47c0,1.86-1.61,3.37-3.56,3.37h-11.55c-1.96,0-3.55-1.51-3.56-3.42v-16.42c0-1.65-.67-3.31-1.94-4.78-.48-.56-.74-1.26-.74-1.98v-11.36c0-1.67,1.36-3.03,3.03-3.03h17.98c1.67,0,3.03,1.36,3.03,3.03,0,0,0,11.36,0,11.36Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="m29.19,53.38c.44,0,.8-.36.8-.8s-.36-.8-.8-.8h-5.26c-.44,0-.8.36-.8.8s.36.8.8.8h5.26Z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="m33.71,55.16h-14.3c-.44,0-.8.36-.8.8s.36.8.8.8h14.3c.44,0,.8-.36.8-.8,0-.45-.36-.8-.8-.8Z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="m33.71,58.53h-14.3c-.44,0-.8.36-.8.8s.36.8.8.8h14.3c.44,0,.8-.36.8-.8,0-.44-.36-.8-.8-.8Z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="m29.19,61.9h-5.26c-.44,0-.8.36-.8.8s.36.8.8.8h5.26c.44,0,.8-.36.8-.8s-.36-.8-.8-.8Z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgWalkieTalkie;
