import * as React from "react";
import { SVGProps } from "react";

const SvgCaseStudy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.375,8.5H10.5 M16.375,11.5H10.5 M16.375,14.5H10.5 M16.375,17.5H10.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M19.5,4.5v15c0,1.105-0.895,2-2,2h-8c-1.105,0-2-0.895-2-2v-13c0-1.105,0.895-2,2-2H19.5z M16.5,4.5v-2h-10 c-1.105,0-2,0.895-2,2v13c0,1.105,0.895,2,2,2h1"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgCaseStudy;
