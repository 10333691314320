import * as React from "react";
import { SVGProps } from "react";

const SvgShowPassword = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,18.5c-6.4,0-9.3-6-9.5-6.3c-0.1-0.2-0.1-0.3,0-0.5c0.1-0.2,3-6.2,9.5-6.2s9.3,6,9.5,6.3 c0.1,0.2,0.1,0.3,0,0.5C21.3,12.5,18.4,18.5,12,18.5z M12,15.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5 C15.5,13.9,14,15.5,12,15.5L12,15.5z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgShowPassword;
