import React from "react";
import * as S from "./CardContainer.styled";
import { CardContainerProps } from "./CardContainer.types";

export const CardContainer: React.FC<CardContainerProps> = ({
  background = "bgPanel100",
  boxShadow = true,
  children,
  border = false,
  variant = "small",
  noPaddingX,
  noPaddingY,
  ...props
}) => {
  return (
    <S.CardContainer {...props}>
      <S.Card
        {...{
          background,
          boxShadow,
          border,
          variant,
          noPaddingX,
          noPaddingY,
        }}
      >
        {children}
      </S.Card>
    </S.CardContainer>
  );
};
