import * as React from "react";
import { SVGProps } from "react";

const SvgBiometricAuthentication = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.902,11.781c0,4.145,0.461,5.066-2.773,8.289 M17.727,5.875c-1.969-1.93-4.766-2.762-7.469-2.215 C7.551,4.207,5.293,6.055,4.227,8.598 M20.223,14.926c0.023-0.625,0.02-1.25,0.012-1.906c0-0.391-0.012-0.797-0.012-1.23 c0-1.438-0.375-2.852-1.09-4.098 M16.543,19.238c0.875-1.984,0.953-3.871,0.918-6.176c0-0.375-0.012-0.773-0.012-1.199 c0-3.059-2.527-5.633-5.598-5.598c-3.035,0.02-5.488,2.48-5.5,5.516c0,0.543,0,1.023,0.012,1.445c0,0.25,0.008,0.527,0.012,0.793 c0,0.582-0.219,1.141-0.613,1.566c-0.148,0.164-0.34,0.355-0.562,0.578c-0.203,0.207-0.391,0.43-0.562,0.664 M14.598,15.934 c0.105-0.852,0.105-1.766,0.09-2.832c0-0.414-0.012-0.844-0.012-1.32c-0.031-1.512-1.266-2.715-2.773-2.715 S9.16,10.27,9.129,11.781c0,0.516,0,0.988,0.012,1.402c0.023,1.387,0.023,2.078-0.156,2.594c-0.191,0.559-0.75,1.273-1.816,2.336 c-0.215,0.215-0.391,0.461-0.527,0.734 M12.488,20.5c0.613-0.711,1.125-1.508,1.52-2.359"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgBiometricAuthentication;
