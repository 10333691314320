import * as React from "react";
import { SVGProps } from "react";

const SvgMaximizeImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <line
        vectorEffect="non-scaling-stroke"
        x1={3.5}
        y1={20.5}
        x2={10.587}
        y2={13.425}
        fill="none"
        stroke="#0D0D0D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <polyline
        vectorEffect="non-scaling-stroke"
        points="7.5,20.5 3.5,20.5 3.5,16.375"
        fill="none"
        stroke="#0D0D0D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <line
        vectorEffect="non-scaling-stroke"
        x1={13.4}
        y1={10.6}
        x2={20.5}
        y2={3.5}
        fill="none"
        stroke="#0D0D0D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <polyline
        vectorEffect="non-scaling-stroke"
        points="16.5,3.5 20.5,3.5 20.5,7.5"
        fill="none"
        stroke="#0D0D0D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
    </g>
  </svg>
);

export default SvgMaximizeImage;
