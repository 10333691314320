import * as React from "react";
import { SVGProps } from "react";

const SvgModbus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.2,12.5c-0.6-0.2-1.1-0.2-1.6-0.2c0.2-0.4,0.4-1,0.4-1.6c0-1.3-0.6-2.4-1.4-2.4s-1.4,1.1-1.4,2.4 c0,0.8,0.2,1.5,0.6,1.9c0,1.1-0.7,2-1.7,2.4c0.1-0.4,0.2-0.8,0.2-1.3c0-1.3-0.6-2.4-1.4-2.4c-0.8,0-1.4,1.1-1.4,2.4 c0,0.6,0.1,1.1,0.3,1.5h-1.2c-0.1-0.5-0.2-1-0.5-1.3c0.3-0.8,0.5-1.7,0.6-2.5c0.3,0,0.5-0.1,0.8-0.2c0.9-0.4,1.4-1.2,1.1-1.8 c-0.2-0.5-1-0.7-1.8-0.4c0-0.4-0.1-0.8-0.1-1.2c0.5,0.1,1.1,0,1.7-0.3c1.1-0.5,1.8-1.6,1.5-2.3s-1.5-0.9-2.7-0.4 c-0.3,0.1-0.5,0.3-0.8,0.5c0-0.3-0.1-0.6-0.3-0.9c-0.5-1.2-1.5-1.9-2.2-1.6C9.2,3.1,9,4.3,9.5,5.5c0.3,0.7,0.8,1.2,1.2,1.5 c0.5,1.9,0.4,4-0.2,5.9c-0.2,0-0.4-0.1-0.5-0.1H8.6c-1.2,0-2.3-0.9-2.5-2.2c0.5,0.1,1.2,0,1.9-0.3c1-0.6,1.7-1.7,1.3-2.4 C8.9,7.2,7.7,7.1,6.6,7.7c-0.1,0-0.2,0.1-0.2,0.1C6.5,7.4,6.6,7,6.6,6.6c0-1.3-0.6-2.4-1.4-2.4S3.7,5.3,3.7,6.6 c0,0.4,0.1,0.8,0.2,1.2c-0.1,0-0.1-0.1-0.2-0.1C2.6,7.1,1.4,7.2,1,7.9s0.3,1.8,1.4,2.4c0.7,0.4,1.4,0.5,1.9,0.3 c0.2,2,1.7,3.6,3.6,3.9c-0.2,0.5-0.3,1,0,1.3c0.4,0.5,1.3,0.3,2-0.4c0.2-0.2,0.4-0.5,0.5-0.7c0.2,0.2,0.4,0.4,0.4,0.8v2 c-1.7-0.2-3.4-0.8-4.8-1.8c-0.2-0.5-0.6-1.1-1.2-1.5c-1-0.7-2.2-0.8-2.7-0.1c-0.4,0.7,0,1.8,1.1,2.6c0.4,0.3,0.9,0.5,1.3,0.6 c-1.1,0.7-1.6,1.8-1.2,2.5s1.6,0.8,2.7,0.1c0.6-0.4,1-0.8,1.2-1.3c1.1,0.5,2.3,0.8,3.6,0.9V21h1.7v-4h1.9c0.1,0.5,0.3,1.1,0.8,1.7 c0.8,1,2,1.3,2.6,0.8s0.4-1.8-0.5-2.8c-0.1-0.1-0.2-0.2-0.2-0.2s1.4-1.1,1.8-2c0.3,0.3,0.7,0.6,1.3,0.8c1.2,0.4,2.4,0.1,2.6-0.6 C23.1,13.9,22.3,12.9,21.2,12.5z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgModbus;
