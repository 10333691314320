import * as React from "react";
import { SVGProps } from "react";

const SvgDrone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.9,15.2c-1.7,1.2-4,1.2-5.8,0 M16.2,16.6c-2.5,1.7-5.8,1.7-8.3,0"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.3,11.2c-2.3,0-4.5,0.4-4.5,2.1h-3.6c0-1.8-2.3-2.1-4.5-2.1 M12,7.4H9.3v1.8H5.7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.7,9.2V7.4H12"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.3,9.2h-3.6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.7,6.1v6.1 M3.4,6.1h4.5 M16.1,6.1h4.5 M18.3,6.1v6.1"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.5,11h0.9"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgDrone;
