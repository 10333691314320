import styled, { css } from "styled-components";
import { CardStyledProps } from "./CardContainer.types";
import { isGradient, spacing } from "../../../theme";
import { deviceUp } from "../Grid";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PaddingMixin = css<CardStyledProps>`
  ${({ variant }) => css`
    padding: ${variant === "medium" ? spacing("m") : spacing("s")};
    @media ${deviceUp.m} {
      padding: ${variant === "medium" ? spacing("l") : spacing("m")};
    }
  `}

  ${({ noPaddingX }) =>
    noPaddingX &&
    css`
      padding-right: 0;
      padding-left: 0;
      @media ${deviceUp.xs} {
        padding-right: 0;
        padding-left: 0;
      }
    `}  
    
    ${({ noPaddingY }) =>
    noPaddingY &&
    css`
      padding-top: 0;
      padding-bottom: 0;
      @media ${deviceUp.xs} {
        padding-top: 0;
        padding-bottom: 0;
      }
    `}
`;

export const Card = styled.div<CardStyledProps>`
  position: relative;
  width: 100%;
  border-radius: 6px;
  height: 100%;
  color: ${({ theme }) => theme.color.monochrome600};

  ${PaddingMixin}

  ${({ background, theme }) =>
    isGradient(background)
      ? css`
          ${theme.color.gradient[background]};
          color: ${theme.color.primary200};
        `
      : css`
          background: ${theme.color[background]};
          background-repeat: no-repeat;
        `}

  ${({ boxShadow }) =>
    boxShadow &&
    `
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  `}
`;
