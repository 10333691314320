import * as React from "react";
import { SVGProps } from "react";

const SvgScreenSize = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.603,20.086h3.256v-3.291 M10.372,3.914H7.141v3.189"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.154,1.769C5.964,1.769,5,2.734,5,3.923v16.154c0,1.19,0.964,2.154,2.154,2.154h9.692 c1.19,0,2.154-0.964,2.154-2.154V1.769H7.154z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgScreenSize;
