import * as React from "react";
import { SVGProps } from "react";

const SvgTickets = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.39,13.631c0.291-0.291,0.762-0.291,1.052,0l0,0l1.776-1.776c0.291-0.291,0.291-0.762,0-1.052l-4.912-4.912 c-0.291-0.291-0.762-0.291-1.052,0l-1.776,1.776c0.291,0.291,0.291,0.762,0,1.052s-0.762,0.291-1.052,0l0,0l-6.644,6.644 c-0.291,0.291-0.291,0.762,0,1.053l4.912,4.912c0.291,0.291,0.762,0.291,1.052,0l6.644-6.644l0,0 C17.099,14.393,17.099,13.922,17.39,13.631z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.949,7.531l0.567-1.525c0.143-0.385-0.053-0.814-0.438-0.957l-6.51-2.422 c-0.385-0.143-0.814,0.053-0.957,0.438L8.735,5.419C9.119,5.566,9.311,5.997,9.163,6.38C9.018,6.758,8.597,6.951,8.216,6.814 l-3.03,8.145"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.19,3.744L6.548,3.507C6.138,3.471,5.777,3.773,5.741,4.182c0,0,0,0,0,0L5.518,6.684 c0.409,0.042,0.706,0.408,0.664,0.817c-0.042,0.4-0.394,0.695-0.795,0.666L4.74,15.414"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={9.796}
        cy={6.647}
        r={0.497}
        stroke="none"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={11.172}
        cy={7.159}
        r={0.497}
        stroke="none"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12.989}
        cy={9.187}
        r={0.497}
        stroke="none"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={14.027}
        cy={10.225}
        r={0.497}
        stroke="none"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={14.956}
        cy={11.153}
        r={0.497}
        stroke="none"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={15.884}
        cy={12.082}
        r={0.497}
        stroke="none"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={16.937}
        cy={13.134}
        r={0.497}
        stroke="none"
      />
    </g>
  </svg>
);

export default SvgTickets;
