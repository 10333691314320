import * as React from "react";
import { SVGProps } from "react";

const SvgPodcastBoxed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="#ffffff">
      <path d="M12,13.5L12,13.5c1.4,0,2.5,1.1,2.5,2.5v3.5c0,1.4-1.1,2.5-2.5,2.5l0,0c-1.4,0-2.5-1.1-2.5-2.5V16 C9.5,14.6,10.6,13.5,12,13.5z M12,7.5c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5S9.5,11.4,9.5,10S10.6,7.5,12,7.5z M16,13.5 c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.3-0.5-0.1-0.7c0.6-0.8,0.9-1.7,0.9-2.7c0-2.5-2-4.5-4.5-4.5s-4.5,2-4.5,4.5c0,1,0.3,1.9,0.9,2.7 c0.2,0.2,0.1,0.5-0.1,0.7c-0.2,0.2-0.5,0.1-0.7-0.1c-1.8-2.4-1.3-5.9,1.1-7.7s5.9-1.3,7.7,1.1c1.5,2,1.5,4.6,0,6.6 C16.3,13.4,16.2,13.5,16,13.5z M17.8,15.3c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.2-0.5-0.1-0.7c2.5-3,2.1-7.4-0.9-9.9S9.1,2.6,6.6,5.5 c-2.1,2.6-2.1,6.3,0,8.9c0.2,0.2,0.1,0.5-0.1,0.7c-0.2,0.2-0.5,0.2-0.7,0c0,0,0,0,0,0C3,11.7,3.5,6.7,6.9,3.8s8.4-2.3,11.3,1.1 c2.5,3,2.5,7.2,0,10.2C18.1,15.2,17.9,15.3,17.8,15.3z" />
    </g>
  </svg>
);

export default SvgPodcastBoxed;
