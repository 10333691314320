import { css } from "styled-components";
import { spacing } from "../theme";
import { deviceUp } from "../components/GlobalComponents/Grid";

/**
 * By using this mixin in a ::before or ::after pseudo-element you can prevent a
 * horizontal layout shifts if a hover effect is created with font-weight bold.
 * It creates a hidden bold text which creates the space for the "real" bold text.
 * Ensure to set the content attribute on the element.
 * Ref: https://stackoverflow.com/questions/556153/inline-elements-shifting-when-made-bold-on-hover
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const preventNormalToBoldLayoutShift = (
  textStyles: any,
  contentAttr?: string
) => css`
  color: transparent;
  display: block;
  content: attr(${contentAttr ? contentAttr : "data-text"});
  height: 0;
  overflow: hidden;
  visibility: hidden;
  ${textStyles};
  font-weight: bold;
`;

export type SectionPaddingMixinProps = {
  paddingBottom?: boolean | SectionPaddingMultipleBreakpointProps;
  paddingTop?: boolean;
};

export type SectionPaddingMultipleBreakpointProps = {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
};

const sectionPaddingBottom = css`
  ${`padding-bottom: ${spacing("xxl")}`};

  @media ${deviceUp.m} {
    ${`padding-bottom: ${spacing("xxxl")}`};
  }
`;

const sectionPaddingBottomForMultipleBreakpoints = (
  paddingBottom: SectionPaddingMultipleBreakpointProps
) => css`
  ${paddingBottom.mobile && `padding-bottom: ${spacing("xxl")}`};

  @media ${deviceUp.m} {
    ${paddingBottom.tablet
      ? `padding-bottom: ${spacing("xxxl")}`
      : "padding-bottom: 0"};
  }

  @media ${deviceUp.l} {
    ${paddingBottom.desktop
      ? `padding-bottom: ${spacing("xxxl")}`
      : "padding-bottom: 0"};
  }
`;

export const sectionPaddingBottomMixin = css<SectionPaddingMixinProps>`
  ${({ paddingBottom }) =>
    paddingBottom
      ? typeof paddingBottom === "object"
        ? sectionPaddingBottomForMultipleBreakpoints(paddingBottom)
        : sectionPaddingBottom
      : undefined};
`;
export const sectionPaddingTopMixin = css<SectionPaddingMixinProps>`
  ${({ paddingTop }) => paddingTop && `padding-top: ${spacing("xxl")}`};

  @media ${deviceUp.m} {
    ${({ paddingTop }) => paddingTop && `padding-top: ${spacing("xxxl")}`};
  }
`;
