import { ITheme } from "react-styled-flexboxgrid";
import { Dimensions } from "./dimensions";
import { baseFontSize, breakpointsInPixel } from "./layout";

export const breakpointsInEm = {
  [Dimensions.xs]: breakpointsInPixel.xs / baseFontSize,
  [Dimensions.s]: breakpointsInPixel.s / baseFontSize,
  [Dimensions.m]: breakpointsInPixel.m / baseFontSize,
  [Dimensions.l]: breakpointsInPixel.l / baseFontSize,
  [Dimensions.xl]: breakpointsInPixel.xl / baseFontSize,
};

export default {
  gridSize: 12, // columns
  gutterWidth: 0, // rem
  outerMargin: 0, // rem
  mediaQuery: "only screen",
  breakpoints: {
    xs: 0, // em
    sm: breakpointsInEm.s, // em - 480px
    md: breakpointsInEm.m, // em - 768px
    lg: breakpointsInEm.l, // em - 1025px
  },
} as ITheme;
