import * as React from "react";
import { SVGProps } from "react";

const SvgFlower = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.2,5.6V5.4c0-1.1-0.9-2-2-2s-2.1,0.9-2.1,2v0.2C9.7,5.4,9.3,5.3,8.9,5.3c-1.1,0-2,0.9-2,2 c0,0.8,0.5,1.6,1.1,1.9c-0.7,0.2-1.1,1-1.1,1.8c0,1.1,0.9,2,2,2c0.4,0,0.8-0.2,1.1-0.3v0.2c0,1.1,0.9,2,2,2s2-0.9,2-2v-0.2 c0.3,0.2,0.7,0.3,1.1,0.3c1.1,0,2-0.9,2-2c0-0.8-0.5-1.6-1.1-1.9c1.1-0.5,1.5-1.7,1-2.7c-0.5-1.1-1.7-1.5-2.7-1L14.2,5.6z M12.1,7.1 c1.1,0,2,0.9,2,2s-0.9,2.1-2,2.1s-2-0.9-2-2S11,7.1,12.1,7.1z M18.7,15.2c-0.1,2.4-2.1,4.2-4.4,4.2c-0.4,0-0.8-0.1-1.2-0.2 c-0.5-2.4,0.9-4.7,3.2-5.3c0.4-0.1,0.7-0.1,1.1-0.1h1.2V15.2z M12.1,20.6c1.3-2,2.9-3.7,4.7-5.1 M5.3,13.6h1.3 c0.4,0,0.8,0.1,1.2,0.2c2.4,0.6,3.9,3,3.3,5.4c-0.4,0.1-0.8,0.2-1.2,0.2c-2.4,0-4.5-1.9-4.6-4.3L5.3,13.6z M7.2,15.4 c1.9,1.5,3.6,3.2,4.9,5.2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgFlower;
