import color from "./color-scheme";
import layout from "../layout";
import text from "../text";
import flexboxgrid from "../grid";

export default {
  name: "ws10dark",
  color,
  layout,
  text,
  flexboxgrid,
};
