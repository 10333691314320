import { DimensionTypes } from "./layout";

export const Dimensions: {
  [key in DimensionTypes]: DimensionTypes;
} = {
  xxs: "xxs",
  xs: "xs",
  s: "s",
  m: "m",
  l: "l",
  xl: "xl",
  xxl: "xxl",
  xxxl: "xxxl",
};
