import * as React from "react";
import { SVGProps } from "react";

const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <path d="M23.371 17.846l.082.194L14.881 2.99C14.148 1.707 13.125 1 12.001 1c-1.123 0-2.146.707-2.88 1.992L.631 17.846c-.735 1.285-.833 2.5-.268 3.482.565.983 1.68 1.488 3.148 1.488h16.98c1.469 0 2.587-.507 3.149-1.488.561-.98.466-2.197-.269-3.482zm-11.37 2.48c-.595-.002-1.076-.494-1.073-1.098.003-.605.488-1.092 1.083-1.09.594.003 1.074.492 1.074 1.095-.001.605-.486 1.095-1.082 1.094h-.002zM13.076 6.61l-.353 8.978c0 .405-.323.732-.722.732-.398 0-.721-.327-.721-.732l-.357-8.977c-.078-.602.34-1.154.934-1.232.593-.08 1.137.345 1.214.947.013.095.013.19 0 .285l.005-.001z" />
      </g>
    </g>
  </svg>
);

export default SvgWarning;
