import * as React from "react";
import { SVGProps } from "react";

const SvgOutsideTemperature = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.4,11.1c0-0.2,0-0.3,0-0.4c0-1.9-1.6-3.5-3.5-3.5c-1.4,0-2.6,0.9-3.2,2 C10.3,9.1,10.1,9,9.7,9c-2.4,0-4.4,2-4.4,4.4c0,0.1,0,0.2,0,0.3c-1.1,0.4-1.9,1.6-1.9,3c0,1.7,1.4,3.2,3.2,3.2h10.8 c1.7,0,3.2-1.4,3.2-3.2c0-1.4-0.7-2.4-1.9-3C18.6,12.7,18.1,11.7,17.4,11.1z"
        fill="none"
        strokeLinecap="round"
      />
      <line
        vectorEffect="non-scaling-stroke"
        x1={14}
        y1={4.1}
        x2={14}
        y2={5.8}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        vectorEffect="non-scaling-stroke"
        x1={9.2}
        y1={5.3}
        x2={10.4}
        y2={6.5}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        vectorEffect="non-scaling-stroke"
        x1={18.9}
        y1={5.7}
        x2={17.6}
        y2={6.8}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        vectorEffect="non-scaling-stroke"
        x1={20.6}
        y1={10.2}
        x2={18.8}
        y2={10.1}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.7,9.2c0.4,1.1,4.5,2.6,6.7,1.9"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgOutsideTemperature;
