import * as React from "react";
import { SVGProps } from "react";

const SvgCarer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.402,17.875c0,0,0.68-0.59,1.203-0.613c0.648-0.031,4.793,0.008,4.793,0.008 c0.41,0.004,0.754-0.32,0.777-0.73c0.023-0.414-0.281-0.77-0.691-0.816c0,0-3.469-0.691-4.344-0.879 c-0.5-0.105-1.07-0.273-1.5-0.406c-0.961-0.309-2.004-0.227-2.906,0.23c-0.906,0.473-2.191,1.832-2.562,3.73"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.125,16.211l5.016-2.25c0.434-0.199,0.949-0.051,1.207,0.355c0.281,0.441,0.148,1.027-0.293,1.309 c-0.023,0.016-0.055,0.035-0.082,0.047c-2.223,1.125-8.434,4.375-8.82,4.543l-0.109,0.043c-0.5,0.188-1,0.211-1.555,0.211H5.25"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M19.312,14.34c0-0.215-0.051-0.426-0.148-0.613c-0.258-0.402-0.773-0.555-1.207-0.355l-4.613,2.125"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.305,14.133c0-0.215-0.051-0.422-0.148-0.613c-0.258-0.402-0.773-0.555-1.207-0.352l-4.008,1.844"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.5,17.73l3.73,3.723"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.438,10.551c-0.426-2.48-1.84-3.758-3.418-3.758c-1.574,0-3.027,1.277-3.457,3.758"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,2.562h2.09v2.09c0,1.156-0.938,2.09-2.09,2.09s-2.09-0.934-2.09-2.09C9.91,3.5,10.848,2.562,12,2.562z"
        fill="none"
      />
    </g>
  </svg>
);

export default SvgCarer;
