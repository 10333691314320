import * as React from "react";
import { SVGProps } from "react";

const SvgStandard2G = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.25,12.206H17v1.5c0.017,0.55-0.344,1.041-0.875,1.188C15.09,15.176,13,15.36,13,11.956 c0-3.688,3.32-3.236,3.812-2.312 M9.95,11.694c0.355-0.262,0.568-0.674,0.575-1.115c0-0.827-0.784-1.597-1.75-1.597 C8.183,8.965,7.628,9.271,7.325,9.78 M10.789,14.995h-3.61c0.001-0.571,0.25-1.113,0.682-1.486l2.116-1.834"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,12c0,5.247-4.253,9.5-9.5,9.5S2.5,17.247,2.5,12S6.753,2.5,12,2.5h9.5V12z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgStandard2G;
