import React, { FC } from "react";
import * as S from "./LoadingScreen.styles";
import { LoadingScreenProps } from "./LoadingScreen.types";
import { LoadingAnimation } from "../LoadingAnimation";

export const LoadingScreen: FC<LoadingScreenProps> = ({
  animation,
  show,
  ...props
}) => {
  return (
    <S.LoadingScreen show={show} {...props}>
      <LoadingAnimation animation={animation} />
    </S.LoadingScreen>
  );
};
