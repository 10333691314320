import React, { FC } from "react";
import * as S from "./Toggle.styles";
import { ToggleProps } from "./Toggle.types";

export const Toggle: FC<ToggleProps> = ({ onClick, checked }) => {
  return (
    <S.Toggle>
      <input
        type={"checkbox"}
        onClick={onClick}
        checked={checked}
        onChange={() => {
          // this empty function is to avoid error messages with react
        }}
      />
    </S.Toggle>
  );
};
