import * as React from "react";
import { SVGProps } from "react";

const SvgFaceRecognition = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.5,6.5v-3h-3"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.5,3.5h-3v3 M3.5,17.5v3h3 M17.5,20.5h3v-3 M11.992,10.832v0.695 M8.508,8.48V9 M15.492,8.48v0.5 M13.992,14.961c-1.23,0.746-2.773,0.746-4,0"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgFaceRecognition;
