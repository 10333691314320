import * as React from "react";
import { SVGProps } from "react";

const SvgPasswordKey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M13,11.031l3.289-3.289"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.484,3.516h-2.793l-7.352,7.359c-2.047-0.797-4.379-0.168-5.746,1.551 c-1.367,1.723-1.457,4.137-0.215,5.953c1.242,1.812,3.52,2.609,5.621,1.961s3.535-2.594,3.535-4.793 c0.008-0.605-0.102-1.211-0.328-1.777l1.309-1.27H16.5l-0.016-2.082l0.914-0.914L18.5,9.492V7.48h1.984V3.516z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={8}
        cy={16}
        r={1.5}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgPasswordKey;
