import * as React from "react";
import { SVGProps } from "react";

const SvgFitnessHealth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.6,10.2C3.2,8.2,3.5,6,4.9,4.6C6.7,2.9,9.5,3,11.2,4.8c0.3,0.3,0.6,0.7,0.8,1.1 M12,20.6 c-0.9-0.5-1.7-1.1-2.4-1.8c-1.4-1.2-2.7-2.5-3.9-4 M12,20.6c0.9-0.5,1.7-1.1,2.4-1.8c2.1-1.7,4.1-3.8,5.3-6.3 c1.1-2.3,1.4-5.9-0.6-7.8c-1.8-1.7-4.6-1.6-6.3,0.2c-0.3,0.3-0.6,0.7-0.8,1.1 M12.4,7.1c-0.1-0.5-0.3-1-0.4-1.2 M3.4,12.5h2.7 l1.8-3.6l1.8,6.3l1.8-4.5l0.9,1.8h3.6"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgFitnessHealth;
