import * as React from "react";
import { SVGProps } from "react";

const SvgHomeGarden = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.8,6.3c0,0-3.9,3.5-4.3,3.8c-0.6,0.6,0.1,1.5,0.9,1.5H15c0.8,0,1.4-1,0.9-1.5s-4.3-3.8-4.3-3.8 C10.8,5.7,10.3,5.9,9.8,6.3z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.6,8.9V6.7h-1.4c-0.3,0-0.6,0.1-0.8,0.3 M14.6,11.6v2.3 M14.6,16.6c-0.2,0.5-0.5,0.8-1.1,0.8H7.7 c-0.6,0-1.1-0.5-1.1-1.1v-4.7 M18,14.4c-0.7,0.7-1.4,0.8-2.1,0.8c0-0.9,0.1-1.4,0.8-2.1c0.7-0.7,1.4-0.8,2.1-0.8 C18.8,13.1,18.7,13.7,18,14.4L18,14.4z M15.9,15.2v3.6"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.3,15.8c0.9,0.9,1.7,1,2.6,1c0-1.1-0.1-1.7-1-2.6c-0.9-0.9-1.8-1-2.6-1C12.3,14,12.5,14.9,13.3,15.8z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgHomeGarden;
