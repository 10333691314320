import styled from "styled-components";
import { spacing } from "../../../theme";

export const DataLine = styled.div`
  padding: ${spacing("s", 0, "xs", 0)};
  border-bottom: solid 1px ${({ theme }) => theme.color.monochrome400};
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &:last-of-type {
    border-bottom: none;
  }

  svg {
    vertical-align: center;
    width: 32px;
    height: 32px;
    margin-right: ${spacing("xs")};

    g {
      fill: ${({ theme }) => theme.color.monochrome600};
      stroke: ${({ theme }) => theme.color.monochrome600};
    }
    path {
      fill: ${({ theme }) => theme.color.monochrome600};
      stroke: ${({ theme }) => theme.color.monochrome600};
    }
  }
`;
