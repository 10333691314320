import * as React from "react";
import { SVGProps } from "react";

const SvgAllRewardsSystem = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.5,11.516v7c0,1.102-0.895,2-2,2h-13c-1.105,0-2-0.898-2-2v-7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,10.516c0,0.551-0.449,1-1,1h-17c-0.551,0-1-0.449-1-1v-1c0-0.555,0.449-1,1-1h18V10.516z"
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.961,8.52c-1.383,0-2.5-1.121-2.5-2.5c0-1.102,0.891-1.996,1.992-2H8c1.379,0,2.5,1.117,2.5,2.5v2"
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.5,8.516v-2c0-1.383,1.121-2.5,2.5-2.5h1.539c1.105,0,2,0.895,2,2c0,1.379-1.117,2.5-2.5,2.5"
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.5,20.516v-12"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.5,8.516v12"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.5,8.516V7.059c0-0.828,0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5v1.457"
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
    </g>
  </svg>
);

export default SvgAllRewardsSystem;
