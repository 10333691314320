import * as React from "react";
import { SVGProps } from "react";

const SvgAcceleration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.3,10c-1-2.8-3.7-4.6-6.6-4.5c-3,0.1-5.5,2.1-6.2,5 M5.5,10.5c0.6-2.3,2.7-4.1,5.1-4.1c2.3,0,4.2,1.5,5,3.6 M15.6,10h2.8 M18.5,12H12"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,12c0,5.2-4.3,9.5-9.5,9.5S2.5,17.2,2.5,12S6.8,2.5,12,2.5h9.5V12z"
        fill="none"
        strokeLinecap="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12}
        cy={12}
        r={1}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgAcceleration;
