import { ReactNode } from "react";

export enum SectionBackgroundProps {
  WHITE = "white",
  GREY = "grey",
  BLACK = "black",
  TRANSPARENT = "transparent",
}

export type SectionProps = {
  paddingBottom?:
    | boolean
    | {
        mobile: boolean;
        tablet: boolean;
        desktop: boolean;
      };
  paddingTop?: boolean;
  maxGridWidth?: number;
  children: ReactNode;
  backgroundColor?: SectionBackgroundProps;
};
