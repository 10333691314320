import * as React from "react";
import { SVGProps } from "react";

const SvgNumber9 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,12.027c-0.016,5.242-4.258,9.484-9.5,9.5c-5.242-0.016-9.484-4.258-9.5-9.5 c0.016-5.238,4.258-9.484,9.5-9.5h9.5V12.027z"
        fill="none"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.352,16.672l0.488-0.078c2.18-0.348,3.777-2.453,3.777-5.293c0-0.465-0.047-0.926-0.137-1.383l0.059,0.324 c-0.211-1.5-1.145-2.75-2.449-2.75s-2.363,1.23-2.363,2.75c-0.012,0.758,0.27,1.492,0.785,2.047c0.57,0.574,1.41,0.801,2.191,0.586 c0.605-0.156,1.875-0.77,1.91-1.379"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgNumber9;
