import * as React from "react";
import { SVGProps } from "react";

const SvgTickWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#FFFFFF" fill="#FFFFFF">
      <path
        d="M12,24c6.6,0,12-5.4,12-12S18.6,0,12,0S0,5.4,0,12S5.4,24,12,24z M17.8,6.8c0.3-0.3,0.8-0.3,1.1,0 c0.3,0.3,0.3,0.8,0,1.1l-9.2,10c-0.3,0.3-0.8,0.3-1.1,0l-4.1-4.4c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0l3.6,3.8L17.8,6.8z"
        stroke="none"
      />
    </g>
  </svg>
);

export default SvgTickWhite;
