import {
  AccountInfo,
  InteractionRequiredAuthError,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { dataRequest } from "../config/config";
import { DeviceProps } from "./Data.types";

/**
 * All API calls to the database should be kept in this file to make it easy to find, add or update data-calls.
 */

export const dataEndpointConfig = {
  projects: {
    fields:
      "projects?select=id,name,route,imeis,subline,ws10Icon,routeSecondLayer",
    order: "&order=name.asc",
  },
  latestDeviceData: {
    fields: "get_latest_device_data",
    order: "&order=timestamp.desc",
  },
  data: {
    fields:
      "data?select=id,version,imei,timestamp,network,data,project,location,latest",
    order: "&order=timestamp.desc",
  },
};

export async function callApiData(accessToken: string, query: string) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(`https://api.vodafone.dev/iot-db/${query}`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

/**
 * @param instance
 * @param account
 * @param query
 */
export async function getDeviceData(
  instance: IPublicClientApplication,
  account: AccountInfo,
  query: string,
) {
  const request = {
    ...dataRequest,
    account: account,
  };
  return await new Promise<DeviceProps[]>((resolve) => {
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        callApiData(response.accessToken, query).then(
          (response: DeviceProps[]) => {
            resolve(response);
          },
        );
      })
      .catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(request);
        }
      });
  });
}
