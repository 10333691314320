import * as React from "react";
import { SVGProps } from "react";

const SvgDistrictHeating = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.6,7.7V3.4h-2c-0.7,0-1.3,0.6-1.3,1.4v0.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.2,11C4.1,11,3,9.7,3.9,8.8c0.6-0.6,6.3-4.9,6.3-4.9c0.7-0.6,1.5-0.8,2.5,0c0,0,5.5,4.1,6.3,4.9 c0.8,0.8-0.3,2.2-1.4,2.2 M13.7,18.7h2.1c0.9,0,1.7-0.8,1.7-1.7V11 M5.2,11v5.9c0,0.9,0.7,1.7,1.6,1.7H9 M8.1,9.1 c0,1.2,0.3,1.9,1.1,2.9c0.6,0.8,1.2,1.1,1.2,2.9 M10,8.2c0,1.2,0.3,1.9,1.1,2.9c0.6,0.8,1.2,1.1,1.2,2.9 M12,7.2 c0,1.2,0.3,1.9,1.1,2.9c0.6,0.8,1.2,1.1,1.2,2.9 M11.3,16.8v3.8 M9.8,20.6h10.6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgDistrictHeating;
