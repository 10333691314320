import * as React from "react";
import { SVGProps } from "react";

const SvgSmartHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.898,19.855h2.09"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.477,9.527c0.161-0.062,0.312-0.149,0.448-0.253l0.021-0.001c0.021-0.018,0.039-0.039,0.06-0.058 c0.03-0.026,0.056-0.053,0.083-0.081c0.033-0.035,0.063-0.07,0.093-0.107c0.141-0.168,0.238-0.355,0.278-0.553 c0.017-0.072,0.025-0.144,0.028-0.217c0-0.01,0.003-0.019,0.003-0.029c0-0.239-0.082-0.474-0.278-0.67 c-0.195-0.195-0.684-0.59-1.305-1.07l-0.008-0.006V2.566h-1.941c-0.699,0-1.27,0.566-1.273,1.27v0.25 c-0.848-0.625-1.469-1.074-1.469-1.074c-0.395-0.315-0.764-0.452-1.114-0.47c-0.033-0.003-0.066-0.005-0.1-0.005 c-0.033,0.001-0.067,0.002-0.1,0.005c-0.35,0.018-0.719,0.155-1.114,0.47c0,0-0.621,0.449-1.469,1.074L4.095,6.488 c-0.621,0.48-1.109,0.875-1.305,1.07c-0.196,0.196-0.279,0.43-0.278,0.67c-0.001,0.01,0.003,0.019,0.003,0.029 C2.517,8.33,2.525,8.402,2.542,8.474C2.582,8.673,2.68,8.86,2.82,9.028C2.85,9.065,2.88,9.101,2.913,9.135 C2.94,9.163,2.966,9.19,2.996,9.216c0.021,0.019,0.038,0.04,0.06,0.058l0.021,0.001c0.272,0.209,0.607,0.351,0.955,0.351H4.5v6.27 c0.004,0.871,0.707,1.574,1.578,1.578h7.383"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.461,12.723l-0.004-1.945c0-0.691,0.559-1.25,1.25-1.25h5.832V20.25c0,0.691-0.562,1.25-1.25,1.25h-4.578 c-0.688,0-1.25-0.559-1.25-1.25V12.723"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgSmartHome;
