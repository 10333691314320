import * as React from "react";
import { SVGProps } from "react";

const SvgRemainingTime = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.582,12.008L12,12.031v-5 M10.461,16.969c0-0.863-2.016-1.562-4.5-1.562s-4.5,0.699-4.5,1.562 s2.016,1.562,4.5,1.562S10.461,17.832,10.461,16.969z M10.461,16.969v2.949c-0.59,0.691-2.375,1.051-4.5,1.051 s-3.914-0.5-4.5-1.188v-2.875 M2.461,20.41v2c0.586,0.688,2.375,1.125,4.5,1.125s3.91-0.312,4.5-1v-2.5c0,0,0-0.5-1-0.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.461,21.473C11.64,21.483,11.818,21.5,12,21.5c5.246,0,9.5-4.254,9.5-9.5V2.5H12 c-5.246,0-9.5,4.254-9.5,9.5c0,1.324,0.272,2.583,0.761,3.728"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgRemainingTime;
