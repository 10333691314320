import * as React from "react";
import { SVGProps } from "react";

const SvgEsimLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,8.161v7.677c-0.017,0.811-0.689,1.455-1.5,1.439H4c-0.811,0.017-1.483-0.627-1.5-1.439v-5.757L6,6.722h14  M20,6.722c0.811-0.017,1.483,0.627,1.5,1.439 M9.431,14.172c0,0,0.453,0.375,1.283,0.323c0.419-0.025,1-0.29,0.905-1.055 c-0.053-0.252-0.202-0.472-0.416-0.615c-0.35-0.245-0.737-0.431-1.148-0.55c0,0-0.615-0.169-0.625-0.769 c-0.034-0.518,0.357-0.965,0.875-1h0.07c0.418-0.044,0.837,0.078,1.166,0.339 M13.226,10.5v4 M18.569,14.5l-0.264-4l-1.56,3.846 l-1.56-3.846l-0.263,4 M7.394,14.229c-0.325,0.185-0.695,0.279-1.069,0.271c-0.729,0-1.362-0.646-1.362-1.443s0.5-1.545,1.319-1.545 c0.701,0,1.276,0.545,1.276,1.184v0.21H4.969"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgEsimLogo;
