import * as React from "react";
import { SVGProps } from "react";

const SvgRoutines = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,1.914v2.691 M12,19.395v2.691 M1.91,12h2.695 M19.391,12h2.691 M4.891,4.844L6.793,6.75 M17.25,17.203 l1.902,1.906 M4.844,19.156l1.902-1.906 M17.203,6.797l1.902-1.906 M12,7.147c-2.681,0-4.853,2.173-4.853,4.853 S9.319,16.854,12,16.854s4.854-2.173,4.854-4.854S14.681,7.147,12,7.147z M10.258,16.518c2.15-0.386,3.785-2.256,3.785-4.518 s-1.635-4.132-3.785-4.518"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgRoutines;
