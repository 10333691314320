import * as React from "react";
import { SVGProps } from "react";

const SvgSimSwap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.5,7L13.5,7c0-0.398-0.158-0.78-0.44-1.061l-2-2l0,0C10.779,3.658,10.398,3.5,10,3.5H6 C5.172,3.5,4.5,4.172,4.5,5v12c0,0.828,0.672,1.5,1.5,1.5h6c0.828,0,1.5-0.672,1.5-1.5V7z M11,15.25c0,0.414-0.336,0.75-0.75,0.75 h-2.5C7.336,16,7,15.664,7,15.25v-4c0-0.414,0.336-0.75,0.75-0.75h2.5c0.414,0,0.75,0.336,0.75,0.75V15.25z M10.5,18.5V19 c0,0.828,0.672,1.5,1.5,1.5h6c0.828,0,1.5-0.672,1.5-1.5V9c0-0.398-0.158-0.78-0.44-1.061l-2-2l0,0C16.779,5.658,16.398,5.5,16,5.5 h-3.379 M13.5,12.5h2.75c0.414,0,0.75,0.336,0.75,0.75v4c0,0.414-0.336,0.75-0.75,0.75h-2.5c-0.183,0.001-0.361-0.064-0.5-0.184"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgSimSwap;
