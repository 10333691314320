import styled from "styled-components";
import { PickColor, spacing } from "../../../theme";
import { deviceUp } from "../Grid";

export interface HeadlineContainerProps {
  marginTop: boolean;
  marginBottom: boolean;
  color: PickColor<"monochrome500" | "primary200">;
}

export const HeadlineContainer = styled.div<HeadlineContainerProps>`
  color: ${({ theme, color }) => theme.color[color]};

  margin-top: ${({ marginTop }) => (marginTop ? "56px" : 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? spacing("xxxl") : 0)};

  @media ${deviceUp.m} {
    margin-top: ${({ marginTop }) => (marginTop ? spacing("xxxl") : 0)};
    margin-bottom: ${({ marginBottom }) => (marginBottom ? "40px" : 0)};
  }
`;
