import * as React from "react";
import { SVGProps } from "react";

const SvgVHub = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.518,14.33c0-1.948,1.313-3.527,2.933-3.527s2.933,1.579,2.933,3.527"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.107,8.986c0,0.915-0.741,1.656-1.656,1.656S6.795,9.9,6.795,8.986c0-0.915,0.741-1.656,1.656-1.656h1.656 V8.986z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.77,9.746l-1.99,1.973l0-1.974h-0.498c-0.549,0-0.995-0.443-0.995-0.989V3.958 c0-0.546,0.446-0.989,0.995-0.989h8.457v5.787c0,0.546-0.446,0.989-0.995,0.989L15.77,9.746z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.313,4.998h5.386 M14.313,6.303h5.386 M14.313,7.653h5.386"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M19.571,11.357v2.614c0,1.092-0.885,1.978-1.978,1.978H5.674c-1.092,0-1.978-0.885-1.978-1.978V7.634 c0-1.092,0.885-1.978,1.978-1.978h5.04"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.012,15.948c0.66,0,1.672,0.179,2.068,1.256c0.396,1.077,0.705,1.868,0.88,2.528 c0.176,0.66-0.088,1.299-1.848,1.299H4.155c-1.76,0-2.024-0.639-1.848-1.299s0.485-1.45,0.88-2.528 c0.396-1.078,1.409-1.256,2.069-1.256L18.012,15.948z M6.19,18.523h10.832"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgVHub;
