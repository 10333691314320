import * as React from "react";
import { SVGProps } from "react";

const SvgHoneyGathered = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.6,19.3c-0.9,0.9-2.5,0.9-3.5,0c-0.9-0.9-0.9-2.5,0-3.5l1.8-1.7l1.8,1.7C21.5,16.8,21.5,18.3,20.6,19.3z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.8,10.1v3.8l-3.3,1.9l-3.3-1.9v-3.8 M12.6,4.4v3.8l3.3,1.9l3.3-1.9V4.4l-3.3-1.9L12.6,4.4z M6,4.4v3.8 l3.3,1.9l3.3-1.9V4.4L9.3,2.5L6,4.4z M6,8.2l-3.3,1.9v3.8L6,15.8l3.3-1.9 M6,15.8l0,3.8l3.3,1.9l3.3-1.9l0-3.8"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgHoneyGathered;
