import * as React from "react";
import { SVGProps } from "react";

const SvgCalendar14 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.5,19.531c0,1.102-0.895,2-2,2h-13c-1.105,0-2-0.898-2-2v-13c0-1.105,0.895-2,2-2h15V19.531z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.504,18.031v-7l-2.02,1.234 M15.484,11.031v6.996 M16.594,16.039h-4.961l3.852-5.008 M8,2.531v3 M16,2.531v3 M20.5,7.566h-17"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgCalendar14;
