import styled, { css } from "styled-components";
import { CardContainer } from "../CardContainer";
import { fontSize, spacing } from "../../../theme";
import { deviceUp } from "../Grid";

export const AccordionItem = styled(CardContainer)`
  .wrapper--collapse {
    transition: height 500ms;
  }

  html.no-js & {
    .wrapper--collapse {
      overflow: visible !important;
      height: auto !important;
    }
  }
`;

export const TextIconContainer = styled.div`
  padding-right: ${spacing("l")};
  svg {
    width: 32px;
    height: 32px;
    transition: transform 0.4s;

    g {
      fill: ${({ theme }) => theme.color.monochrome600};
      stroke: ${({ theme }) => theme.color.monochrome600};
    }

    @media ${deviceUp.m} {
      width: 36px;
      height: 36px;
      g {
        stroke-width: 2px;
      }
    }
  }
`;

export const TextContainer = styled.div`
  color: ${({ theme }) => theme.color.monochrome600};

  ${fontSize("xxs", "body")};
  padding: ${spacing("xl", "m")};
  @media ${deviceUp.m} {
    ${fontSize("m", "body")};
    padding: ${spacing("xxl", "l")};
  }
`;

export const HeadlineContainer = styled.button`
  display: flex;
  align-items: center;
  padding: ${spacing("m")};
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  width: 100%;
  border: none;
  color: ${({ theme }) => theme.color.monochrome600};
  &:active,
  &:focus {
    outline: none;
  }

  &.focus-visible {
    outline: none;
    border-radius: 6px;
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.secondary100};

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }
  }

  @media ${deviceUp.m} {
    padding: ${spacing("l")};
  }
`;

type IconContainerProps = {
  iconRotate?: boolean;
};

export const IconContainer = styled.div<IconContainerProps>`
  justify-self: self-end;
  display: flex;
  margin-left: auto;

  a,
  svg {
    outline-color: ${({ theme }) => theme.color.state500};
  }

  svg {
    width: 32px;
    height: 32px;
    transition: transform 0.4s;

    @media ${deviceUp.m} {
      width: 36px;
      height: 36px;
    }

    g {
      fill: ${({ theme }) => theme.color.primary100};
      stroke: ${({ theme }) => theme.color.primary100};
    }

    ${({ iconRotate }) =>
      iconRotate &&
      css`
        transform: scaleY(-1);
      `}
  }
`;

type DividerProps = {
  visible?: boolean;
};

export const Divider = styled.div<DividerProps>`
  height: 1px;
  background-color: ${({ theme }) => theme.color.monochrome200};
  margin: ${spacing(0, "m")};
  display: none;
  html.no-js & {
    display: block;
  }

  @media ${deviceUp.m} {
    margin: ${spacing(0, "l")};
  }

  ${({ visible }) =>
    visible &&
    css`
      display: block;
    `};
`;
