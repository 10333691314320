import * as React from "react";
import { SVGProps } from "react";

const SvgStandard5G = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.25,12.207H17v1.5c0.016,0.55-0.345,1.04-0.875,1.188C15.09,15.177,13,15.361,13,11.957 c0-3.688,3.32-3.238,3.812-2.312 M10.48,9.006H7.938v2.625L8.211,11.5c0.936-0.397,2.017,0.039,2.414,0.975 c0.397,0.936-0.039,2.017-0.975,2.414c-0.647,0.275-1.394,0.157-1.925-0.303"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,12c0,5.247-4.253,9.5-9.5,9.5S2.5,17.247,2.5,12S6.753,2.5,12,2.5h9.5V12z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgStandard5G;
