import styled from "styled-components";
import { spacing } from "../../../theme/utils/spacing";

type MenuProps = {
  open: boolean;
};

export const MenuOverlay = styled.div<MenuProps>`
  width: 100vw;
  height: 100vh;
  z-index: ${({ open }) => (open ? 1 : -1)};
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  ${({ open }) => open && `animation: flyout-transition-in 0.33s`};
  animation-fill-mode: forwards;

  @keyframes flyout-transition-in {
    0% {
      background-color: transparent;
      z-index: 10;
    }
    100% {
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 10;
    }
  }
`;

export const MenuWrapper = styled.div<MenuProps>`
  height: 100%;
  width: 280px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: ${({ open }) => (open ? 0 : -280)}px;
  background-color: ${({ theme }) => theme.color.primary400};
  overflow: hidden;
  transition: left 0.3s;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    padding: ${spacing("s")};
    color: ${({ theme }) => theme.color.primary200};
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    transition: background-color ease-in-out 0.2s;

    &:hover {
      text-decoration: none;
      color: ${({ theme }) => theme.color.primary200};
      background-color: ${({ theme }) => theme.color.footerBg};
    }
  }

  svg {
    width: 42px;
    height: 42px;
    margin-right: ${spacing("l")};

    g {
      fill: ${({ theme }) => theme.color.primary200};
      stroke: ${({ theme }) => theme.color.primary200};
    }
  }
`;

export const BottomWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

export const Btn = styled.button`
  padding: ${spacing(0, "s", "l")};
  background-color: transparent;
  border: none;

  svg {
    margin-right: 0;
  }
`;
