import { createGlobalStyle } from "styled-components";

export const BaseCSS = createGlobalStyle`
    html {
        display: flex;
        justify-content: center;
    }

    body {
        background-color: ${({ theme }) => theme.color.background};
        max-width: 2560px;
        width: 100%;
    }

    // Reset links after Bootstrap
    a {
      color: ${({ theme }) => theme.color.monochrome600};
      text-decoration: underline;
      background-color: transparent;

      :hover {
        color: ${({ theme }) => theme.color.primary100};
        text-decoration: underline;
      }
    }
`;
