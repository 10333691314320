import * as React from "react";
import { SVGProps } from "react";

const SvgLinkedinBoxed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="#ffffff">
      <path d="M9.2,9h3.6v1.6l0,0c0.7-1.2,2.1-2,3.6-1.9c3.8,0,4.5,2.5,4.5,5.7V21h-3.7v-5.9c0-1.4,0-3.2-2-3.2 S13,13.5,13,15.1V21H9.2V9z M3.2,9H7v12H3.2V9L3.2,9z M5.2,3c1.2,0,2.2,1,2.2,2.1c0,1.2-1,2.2-2.1,2.2C4,7.4,3,6.4,3,5.2 c0,0,0,0,0,0C3,4,4,3,5.2,3z" />
    </g>
  </svg>
);

export default SvgLinkedinBoxed;
