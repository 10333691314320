import * as React from "react";
import { SVGProps } from "react";

const SvgBusiness = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <line
        vectorEffect="non-scaling-stroke"
        x1={12.5}
        y1={13.4}
        x2={11.5}
        y2={13.4}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13,19.3L12,20.5l-1.1-1.2l0.5-4.4c0-0.3,0.3-0.5,0.6-0.5l0,0c0.3,0,0.5,0.2,0.6,0.5L13,19.3z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.7,21c0-4.9,3.3-8.8,7.3-8.8s7.3,3.9,7.3,8.8"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.1,7.7c0,2.3-1.9,4.1-4.1,4.1S7.9,10,7.9,7.7S9.7,3.6,12,3.6h4.1V7.7z"
        fill="none"
      />
    </g>
  </svg>
);

export default SvgBusiness;
