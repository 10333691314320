import * as React from "react";
import { SVGProps } from "react";

const SvgMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.5,19.5h3 M8.5,2.5h9v17c0,1.1-0.9,2-2,2h-7c-1.1,0-2-0.9-2-2v-15C6.5,3.4,7.4,2.5,8.5,2.5z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgMobile;
