import React, { ReactElement, FC } from "react";
import {
  BrandIcons,
  HifiIcons,
  NotificationIcons,
  SystemIcons,
} from "../../../icons";
import { IconProps } from "./Icon.types";

export const Icon: FC<IconProps> = ({
  as,
  iconPackage = "system",
  ...props
}): ReactElement => {
  if (!as) return <Icon as="Placeholder" />;
  switch (iconPackage) {
    case "system": {
      const IconComponent = (SystemIcons as never)[as] as FC<
        React.SVGProps<SVGElement>
      >;
      if (typeof IconComponent === "undefined") return <></>;
      return <IconComponent {...props} />;
    }
    case "brand": {
      const IconComponent = (BrandIcons as never)[as] as FC<
        React.SVGProps<SVGElement>
      >;
      if (typeof IconComponent === "undefined") return <></>;
      return <IconComponent {...props} />;
    }
    case "hifi": {
      const IconComponent = (HifiIcons as never)[as] as FC<
        React.SVGProps<SVGElement>
      >;
      if (typeof IconComponent === "undefined") return <></>;
      return <IconComponent {...props} />;
    }
    case "notification": {
      const IconComponent = (NotificationIcons as never)[as] as FC<
        React.SVGProps<SVGElement>
      >;
      if (typeof IconComponent === "undefined") return <></>;
      return <IconComponent {...props} />;
    }
    default: {
      return <Icon as="Placeholder" />;
    }
  }
};
