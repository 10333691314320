import * as React from "react";
import { SVGProps } from "react";

const SvgOperatingSystem = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12}
        cy={12}
        r={10.515}
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.099,14.255c0,0,0.616,0.569,1.764,0.569c1.431,0,1.554-1.083,1.568-1.262 c0.018-0.226,0.199-1.006-1.446-1.58c-1.555-0.545-1.572-1.273-1.555-1.63c0.017-0.357,0.277-1.262,1.714-1.139 c0.548,0.059,1.054,0.32,1.42,0.732"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.438,14.798c1.086,0,2.014-0.707,2.014-2.688s-0.816-2.933-2.014-2.933h0.012 c-1.198,0-2.014,0.952-2.014,2.933s0.928,2.688,2.014,2.688"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgOperatingSystem;
