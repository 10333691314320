import * as React from "react";
import { SVGProps } from "react";

const SvgBatteryFull = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.8,7.9h14v6.8c0,0.7-0.6,1.4-1.4,1.4H4.8c-0.7,0-1.4-0.6-1.4-1.4V9.3 C3.4,8.5,4,7.9,4.8,7.9z M18.8,9.8h0.9c0.5,0,0.9,0.4,0.9,0.9v2.7c0,0.5-0.4,0.9-0.9,0.9h-0.9 M6.1,13.4v-2.7 M8.8,13.4v-2.7  M11.5,13.4v-2.7 M14.2,13.4v-2.7 M16.9,13.4v-2.7"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgBatteryFull;
