import * as React from "react";
import { SVGProps } from "react";

const SvgTickGreenIsolated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#009900" fill="#009900">
      <path
        d="M13.5,18l9.4-14.3c0.2-0.3,0.3-0.6,0.3-0.9c0-0.8-0.6-1.3-1.3-1.3l0,0c-0.6,0-0.9,0.3-1.3,0.8l-9.2,11.6 l-2.4,2.9l-5.7-4.9l0,0c-0.3-0.3-0.7-0.6-1.2-0.6c-0.8,0-1.5,0.7-1.5,1.5c0,0.3,0.1,0.8,0.3,1l0.1,0.1l6.9,7.7 c0.3,0.4,0.8,0.9,1.5,0.9c0.7,0,1.1-0.6,1.5-1L13.5,18"
        stroke="none"
      />
    </g>
  </svg>
);

export default SvgTickGreenIsolated;
