import * as React from "react";
import { SVGProps } from "react";

const SvgEngineer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.25,9.051c0.637-1.5,0.301-3.238-0.848-4.395C9.25,3.504,7.512,3.164,6.012,3.801l1.93,2 c0.09,0.094,0.141,0.215,0.141,0.344V7.5c0,0.277-0.223,0.5-0.5,0.5H6.18C6.047,8,5.918,7.949,5.824,7.855l-1.938-1.93 c-0.633,1.5-0.297,3.234,0.855,4.387c1.148,1.148,2.883,1.488,4.383,0.859l8.914,8.914c0.281,0.281,0.664,0.441,1.062,0.441 s0.781-0.16,1.062-0.441s0.441-0.664,0.441-1.062s-0.16-0.781-0.441-1.062L11.25,9.051z M3.918,17.961 c-0.281,0.281-0.441,0.664-0.441,1.062s0.16,0.781,0.441,1.062s0.664,0.441,1.062,0.441s0.781-0.16,1.062-0.441l3.547-3.555 c0.285-0.281,0.441-0.664,0.441-1.062s-0.156-0.781-0.441-1.062c-0.281-0.285-0.664-0.441-1.062-0.441s-0.781,0.156-1.062,0.441 L3.918,17.961z M13.145,10.941l7.004-7 M9.664,14.422l1.355-1.355"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgEngineer;
