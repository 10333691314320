import { createGlobalStyle } from "styled-components";

import VodafoneWoff2 from "./VodafoneVF_Wght_W.woff2";

export const VodafoneFonts = createGlobalStyle`
  @font-face {
    font-family: 'Vodafone';
    src: local('Vodafone'), local('Vodafone'),
    url(${VodafoneWoff2}) format('woff2');
    font-weight: 300 800;
    font-style: normal;
    font-display: swap;
  }
`;
