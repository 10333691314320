import styled from "styled-components";
import { deviceUp } from "../../Grid";

export type BurgerStateProps = {
  isOpen?: boolean;
  light?: boolean;
};

export const BurgerMenu = styled.div<BurgerStateProps>`
  all: initial;
  display: block;
  text-align: center;
  transition: all 0.3s;
  ${({ isOpen, theme }) =>
    isOpen && `background-color: ${theme.color.primary100}}`};
  z-index: 999;
  position: fixed;
  left: ${({ isOpen }) => (isOpen ? 280 : 10)}px;
  cursor: pointer;

  width: 33px;
  height: 33px;

  @media ${deviceUp.m} {
    width: 42px;
    height: 42px;
  }
`;

export const BurgerMenuIcon = styled.div<BurgerStateProps>`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 27px;
  height: 1px;
  background-color: ${({ isOpen, light, theme }) =>
    isOpen
      ? "transparent"
      : light
      ? theme.color.primary200
      : theme.color.monochrome500};
  transition: all 0.3s;

  @media ${deviceUp.m} {
    width: 29px;
    height: 2px;
  }

  &:after,
  &:before {
    content: "";
    display: block;
    background-color: ${({ isOpen, light, theme }) =>
      isOpen || light ? theme.color.primary200 : theme.color.monochrome500};
    position: absolute;
    transition: all 0.3s;

    width: 27px;
    height: 1px;
    @media ${deviceUp.m} {
      width: 29px;
      height: 2px;
    }
  }

  &:after {
    ${({ isOpen }) => isOpen && `transform: translateY(-8px) rotate(-45deg)`};
    top: 8px;
  }

  &:before {
    ${({ isOpen }) => isOpen && `transform: translateY(8px) rotate(45deg)`};
    top: -8px;
  }
`;
