import * as React from "react";
import { SVGProps } from "react";

const SvgSignedIn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.5,16L16,20.5l-1.996-2 M4.676,21c0-4.863,3.281-8.805,7.324-8.805 c2.328,0,4.406,1.309,5.75,3.348"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.1,7.7c0,2.3-1.9,4.1-4.1,4.1S7.9,10,7.9,7.7S9.7,3.6,12,3.6h4.1V7.7z"
        fill="none"
      />
    </g>
  </svg>
);

export default SvgSignedIn;
