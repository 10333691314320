import * as React from "react";
import { SVGProps } from "react";

const SvgChartBar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.6,21.5h18.9 M5.5,9.5H8V18c0,0.3-0.2,0.5-0.5,0.5h-2C5.2,18.5,5,18.3,5,18v-8C5,9.7,5.2,9.5,5.5,9.5z  M16.5,13.5H19V18c0,0.3-0.2,0.5-0.5,0.5h-2c-0.3,0-0.5-0.2-0.5-0.5v-4C16,13.7,16.2,13.5,16.5,13.5z M11,6.5h2.5V18 c0,0.3-0.2,0.5-0.5,0.5h-2c-0.3,0-0.5-0.2-0.5-0.5V7C10.5,6.7,10.7,6.5,11,6.5z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgChartBar;
