import * as React from "react";
import { SVGProps } from "react";

const SvgApps = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.389,17.816H6.454c-0.251,0.011-0.489-0.113-0.625-0.325c-0.123-0.219-0.117-0.487,0.016-0.7l1.969-3.729 c0.152-0.33,0.543-0.473,0.873-0.321c0.142,0.066,0.256,0.179,0.321,0.321l2,3.728c0.133,0.213,0.138,0.481,0.015,0.7 C10.886,17.706,10.644,17.83,10.389,17.816z M15.579,12.559l2.629,2.104l-1.051,3.154h-3.154l-1.051-3.154L15.579,12.559z  M14.336,6.184h2.486c0.549,0,0.994,0.445,0.994,0.994v2.486c0,0.549-0.445,0.994-0.994,0.994h-2.486 c-0.549,0-0.994-0.445-0.994-0.994V7.177C13.342,6.629,13.787,6.184,14.336,6.184z M5.5,3.5h15l0,0v15c0,1.105-0.895,2-2,2h-13 c-1.105,0-2-0.895-2-2v-13C3.5,4.395,4.395,3.5,5.5,3.5z M8.42,6.182c-1.236,0-2.238,1.002-2.238,2.238s1.002,2.238,2.238,2.238 s2.238-1.002,2.238-2.238S9.656,6.182,8.42,6.182z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgApps;
