import * as React from "react";
import { SVGProps } from "react";

const SvgTowelDispenser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.4,12.9L5.4,12.9c-0.8,0-1.5-1.1-1.5-2.5V5.9c0-1.4,0.7-2.5,1.5-2.5h14.8v6.9c-0.1,1.4-0.7,2.5-1.5,2.5"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.6,11.1h12.8"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.4,11.1v3.6c0,0.3-0.3,0.5-0.7,0.5H6.3c-0.4,0-0.7-0.2-0.7-0.5v-3.6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,20.6v-4.1 M10.1,18.7l1.9,1.9l1.9-1.9"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgTowelDispenser;
