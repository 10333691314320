import styled, { css } from "styled-components";
import { preventNormalToBoldLayoutShift } from "../../../styles";
import { CircularButtonVariants } from "./CircularButton.types";
import { fontSize, spacing } from "../../../theme";
import { deviceUp } from "../Grid";

const textStyles = css`
  ${fontSize("xxs", "body")};
`;

export const CircularButtonStrokeWidth = 1.5;

export const CircularButtonLabel = styled.span`
  ${textStyles};
  text-align: center;
  color: ${({ theme }) => theme.color.monochrome600};
  margin-top: ${spacing("xs")};
  height: 48px;
  overflow-y: hidden;
  overflow-x: visible;

  @media ${deviceUp.m} {
    ${fontSize("xxs", "h5")};
  }
`;

type CircularButtonProps = {
  variant?: CircularButtonVariants;
};

const mapIconBackgroundColor = (variant: CircularButtonVariants) => {
  switch (variant) {
    case "red":
      return "primary100";
    case "white":
      return "primary200";
  }
};

const mapIconColor = (variant: CircularButtonVariants) => {
  switch (variant) {
    case "red":
      return "primary200";
    case "white":
      return "primary100";
  }
};

export const CircularButton = styled.button<CircularButtonProps>`
  display: inline-block;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: none;
    cursor: pointer;

    ${CircularButtonLabel} {
      color: ${({ theme }) => theme.color.buttonCircularColor};
      ${({ theme }) =>
        theme.name === "ws10dark" &&
        css`
          font-weight: ${theme.text.weight.bold};
          &::before {
            ${preventNormalToBoldLayoutShift(textStyles)};
          }
        `}
    }
  }

  svg {
    width: 32px;
    height: 32px;

    g {
      fill: ${({ theme }) => theme.color.buttonCircularColor};
      stroke: ${({ theme }) => theme.color.buttonCircularColor};
    }
  }

  ${({ variant }) =>
    variant &&
    css`
      &:hover {
        ${CircularButtonIconBackground} {
          background-color: ${({ theme }) =>
            theme.color[mapIconBackgroundColor(variant)]};
        }
      }

      svg {
        g {
          ${variant &&
          css`
            fill: ${({ theme }) => theme.color[mapIconColor(variant)]};
            stroke: ${({ theme }) => theme.color[mapIconColor(variant)]};
          `}

          stroke-width: ${CircularButtonStrokeWidth};
        }
      }
    `};
`;

type CircularButtonContainerProps = {
  floating?: boolean;
};

export const CircularButtonContainer = styled.div<CircularButtonContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  ${({ floating }) =>
    floating &&
    css`
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.32);
    `};
`;

export const CircularButtonIconBackground = styled.div<CircularButtonProps>`
  border-radius: 50%;
  width: 64px;
  height: 64px;
  border: solid 1px ${({ theme }) => theme.color.buttonCircularColor};
  display: flex;
  align-items: center;
  justify-content: center;
  mask-image: radial-gradient(white, black);
  transition: background-color 0.3s;
  &.focus-visible {
    mask-image: none;
  }

  ${({ variant, theme }) =>
    variant &&
    css`
      border: none;
      background-color: ${theme.color[mapIconBackgroundColor(variant)]};
    `}
`;
