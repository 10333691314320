import React, { FC } from "react";
import * as S from "./DateRangePicker.styles";
import DatePicker from "react-datepicker";
import { DateRangePickerProps } from "./DateRangePicker.types";

export const DateRangePicker: FC<DateRangePickerProps> = ({
  customFn,
  startDate,
  endDate,
}) => {
  return (
    <S.Picker>
      <S.Label>Date range:</S.Label>
      <DatePicker
        selected={startDate}
        onChange={(date: Date) => customFn(date, "start")}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        dateFormat={"yyyy-MM-dd"}
      />
      <DatePicker
        selected={endDate}
        onChange={(date: Date) => customFn(date, "end")}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        dateFormat={"yyyy-MM-dd"}
      />
    </S.Picker>
  );
};
