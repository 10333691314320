import * as React from "react";
import { SVGProps } from "react";

const SvgPPP = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 311.297 311.297"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="none">
      <path
        stroke="#FFFFFF"
        strokeWidth="1" // moderate stroke
        d="M38.359,255.793c-4.817,0-8.736,3.919-8.736,8.736c0,4.817,3.919,8.736,8.736,8.736c4.818,0,8.738-3.919,8.738-8.736
    C47.098,259.712,43.178,255.793,38.359,255.793z"
      />
      <path
        stroke="#FFFFFF"
        strokeWidth="1" // moderate stroke
        d="M265.432,255.793c-4.818,0-8.738,3.919-8.738,8.736c0,4.818,3.92,8.738,8.738,8.738c4.817,0,8.736-3.92,8.736-8.738
    C274.168,259.712,270.249,255.793,265.432,255.793z"
      />
      <path
        stroke="#FFFFFF"
        strokeWidth="1" // moderate stroke
        d="M270.853,218.994l-56.091-203.28c-0.838-3.035-3.6-5.138-6.748-5.138h-16.141c-3.866,0-7,3.134-7,7s3.134,7,7,7h10.811
    l17.617,63.847c-0.409,0.037-0.82,0.104-1.23,0.218c-3.727,1.028-5.914,4.883-4.886,8.61l34.389,124.63
    c-14.886,5.904-25.977,19.375-28.479,35.648H83.692c-3.379-21.981-22.421-38.867-45.333-38.867H7c-3.866,0-7,3.134-7,7
    c0,3.866,3.134,7,7,7h14.225C9.89,238.781,2.169,250.77,2.169,264.529c0,19.955,16.235,36.189,36.19,36.189
    c17.837,0,32.69-12.975,35.646-29.978c0.964,0.501,2.058,0.789,3.221,0.789h149.337c1.162,0,2.256-0.288,3.221-0.789
    c2.955,17.006,17.811,29.98,35.648,29.98c17.836,0,32.689-12.976,35.644-29.98c0.965,0.501,2.059,0.789,3.222,0.789
    c3.866,0,7-3.134,7-7C311.297,241.074,293.596,221.686,270.853,218.994z M38.359,286.719c-12.236,0-22.19-9.954-22.19-22.189
    c0-12.235,9.954-22.189,22.19-22.189c12.235,0,22.19,9.954,22.19,22.189C60.549,276.765,50.595,286.719,38.359,286.719z
    M265.432,286.721c-12.237,0-22.192-9.955-22.192-22.191c0-12.235,9.955-22.189,22.192-22.189c12.234,0,22.188,9.954,22.188,22.189
    C287.619,276.766,277.666,286.721,265.432,286.721z"
      />
    </g>
  </svg>
);

export default SvgPPP;
