import * as React from "react";
import { SVGProps } from "react";

const SvgRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.605,19.469c4.141,0.059,7.547-3.25,7.605-7.391c0.062-4.145-3.25-7.551-7.391-7.609h-0.234 c-4.141,0.004-7.492,3.363-7.492,7.5v3.422 M9.68,11.949l-3.586,3.441l-3.484-3.441"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgRefresh;
