import * as React from "react";
import { SVGProps } from "react";

const SvgContacts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.1,2.6H21l0,0v17c0,1.1-0.9,2-2,2H5.1c-1.1,0-2-0.9-2-2v-15C3.1,3.5,4,2.6,5.1,2.6L5.1,2.6z M18.1,21.6v-19  M18.1,6.6H21 M18.1,12.1H21 M18.1,17.6H21 M14.7,17.1c-0.4-3-2.2-4.9-4-4.9S7,14.1,6.5,17.1 M10.5,7.3H13l0,0v2.5 c0,1.3-1.1,2.4-2.4,2.4h-0.1c-1.3,0-2.4-1.1-2.4-2.4V9.7C8.2,8.4,9.2,7.3,10.5,7.3L10.5,7.3z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgContacts;
