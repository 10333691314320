import * as React from "react";
import { SVGProps } from "react";

const SvgLagerRFID = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="svg4239"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 661.11 608.71"
  >
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html:
            ".cls-1{fill:#677375;}.cls-2{fill:#6a4729;}.cls-3{fill:#819263;}.cls-4{fill:#af7b53;}.cls-5{fill:#d1dad9;}.cls-6{fill:#d2a06d;}.cls-7{fill:#718052;}.cls-8{fill:#353632;}.cls-9{fill:#624831;}.cls-10{fill:#4d4c50;}",
        }}
      />
    </defs>
    <rect className="cls-10" x="131.9" y="194.57" width="470.41" height={410} />
    <polygon
      className="cls-8"
      points="634.03 169.1 634.03 604.56 602.31 604.56 602.31 194.59 131.9 194.59 131.9 604.56 100.17 604.56 100.17 169.1 367.07 58.33 634.03 169.1"
    />
    <polygon
      className="cls-5"
      points="634.03 169.1 634.03 186.34 367.07 75.57 100.17 186.34 100.17 169.1 367.07 58.33 634.03 169.1"
    />
    <rect
      className="cls-1"
      x="100.17"
      y="583.62"
      width="31.72"
      height="20.94"
    />
    <rect
      className="cls-1"
      x="602.31"
      y="583.62"
      width="31.72"
      height="20.94"
    />
    <path
      className="cls-3"
      d="m661.11,119.14v61.19L367.08,58.31,73.11,180.34v-61.19L356.24,2.67c6.77-3.54,14.86-3.54,21.68,0l283.19,116.47Z"
    />
    <polygon
      className="cls-7"
      points="661.1 161.99 661.1 180.34 367.07 58.3 73.1 180.34 73.1 161.99 367.07 40.01 661.1 161.99"
    />
    <path
      className="cls-7"
      d="m661.1,119.14v18.29L377.91,20.95c-6.82-3.54-14.91-3.54-21.68,0L73.1,137.43v-18.29L356.23,2.66c6.77-3.54,14.86-3.54,21.68,0l283.19,116.48Z"
    />
    <rect
      className="cls-4"
      x="275.95"
      y="449.47"
      width="155.91"
      height="155.91"
    />
    <path
      className="cls-6"
      d="m431.86,449.45v155.94h-155.94v-33.46h53.22c38.23,0,69.26-30.94,69.26-69.26v-53.22h33.46Z"
    />
    <rect
      className="cls-4"
      x="431.86"
      y="449.47"
      width="155.91"
      height="155.91"
    />
    <path
      className="cls-6"
      d="m587.77,449.45v155.94h-155.94v-33.46h53.22c38.23,0,69.26-30.94,69.26-69.26v-53.22h33.46Z"
    />
    <rect
      className="cls-4"
      x="353.9"
      y="293.56"
      width="155.91"
      height="155.91"
    />
    <path
      className="cls-6"
      d="m509.85,293.52v155.94h-155.94v-33.46h53.22c38.23,0,69.26-30.95,69.26-69.26v-53.22h33.46Z"
    />
    <rect
      className="cls-9"
      x="334.97"
      y="449.47"
      width="37.87"
      height="47.54"
    />
    <rect
      className="cls-9"
      x="412.92"
      y="293.56"
      width="37.87"
      height="47.54"
    />
    <rect
      className="cls-9"
      x="490.88"
      y="449.47"
      width="37.87"
      height="47.54"
    />
    <path
      className="cls-2"
      d="m372.87,449.46v47.55h-37.91v-14.42h9.07c7.94,0,14.42-6.4,14.42-14.42v-18.71h14.42Z"
    />
    <path
      className="cls-2"
      d="m450.8,293.52v47.55h-37.91v-14.34h9.07c8.02,0,14.42-6.48,14.42-14.42v-18.79h14.42Z"
    />
    <path
      className="cls-2"
      d="m528.73,449.46v47.55h-37.83v-14.42h9.07c7.94,0,14.42-6.4,14.42-14.42v-18.71h14.34Z"
    />
    <rect className="cls-4" x=".02" y="491.83" width="116.88" height="116.88" />
    <path
      className="cls-6"
      d="m116.9,491.81v116.9H0v-25.08h39.9c28.66,0,51.92-23.2,51.92-51.92v-39.9h25.08Z"
    />
    <rect
      className="cls-4"
      x="116.9"
      y="491.83"
      width="116.88"
      height="116.88"
    />
    <path
      className="cls-6"
      d="m233.78,491.81v116.9h-116.9v-25.08h39.9c28.66,0,51.92-23.2,51.92-51.92v-39.9h25.08Z"
    />
    <rect
      className="cls-4"
      x="58.46"
      y="374.95"
      width="116.88"
      height="116.88"
    />
    <path
      className="cls-6"
      d="m175.37,374.92v116.9H58.47v-25.08h39.9c28.66,0,51.92-23.2,51.92-51.92v-39.9h25.08Z"
    />
    <rect className="cls-9" x="44.26" y="491.83" width="28.39" height="35.64" />
    <rect className="cls-9" x="102.7" y="374.95" width="28.39" height="35.64" />
    <rect
      className="cls-9"
      x="161.14"
      y="491.83"
      width="28.39"
      height="35.64"
    />
    <path
      className="cls-2"
      d="m72.68,491.82v35.65h-28.42v-10.81h6.8c5.95,0,10.81-4.8,10.81-10.81v-14.03h10.81Z"
    />
    <path
      className="cls-2"
      d="m131.1,374.92v35.65h-28.42v-10.75h6.8c6.01,0,10.81-4.86,10.81-10.81v-14.09h10.81Z"
    />
    <path
      className="cls-2"
      d="m189.52,491.82v35.65h-28.36v-10.81h6.8c5.95,0,10.81-4.8,10.81-10.81v-14.03h10.75Z"
    />
  </svg>
);
export default SvgLagerRFID;
