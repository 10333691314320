import * as React from "react";
import { SVGProps } from "react";

const SvgAmbulance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.5,7.5h2.766c2.34,0,4.234,1.895,4.234,4.234V14c0,0.828-0.672,1.5-1.5,1.5h-1.586 M4.586,15.5 C3.422,15.461,2.5,14.504,2.5,13.336V7.5c0-1.105,0.895-2,2-2h10v10H7.414 M15.586,15.5H14.5"
        fill="none"
        strokeLinecap="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={6}
        cy={16}
        r={1.5}
        fill="none"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={17}
        cy={16}
        r={1.5}
        fill="none"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.5,8.574v3.938 M6.531,10.543h3.938"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgAmbulance;
