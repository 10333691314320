import styled from "styled-components";
import { spacing, fontSize } from "../../../theme";
import { HeroBackground } from "../../Templates/Hero/Hero.styles";
import { Icon, IconProps } from "../Icon";

export const LoginWrapper = styled(HeroBackground)`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: #fff;
    margin-bottom: ${spacing("xl")};
  }

  button {
    background-color: transparent;
    border: none;
    color: #fff;
    ${fontSize("xxs", "h3")}
  }
`;

export const VfIcon = styled(Icon)<IconProps>`
  width: 200px;
  height: 200px;
  margin-bottom: ${spacing("xxl")};
`;

export const IconWrapper = styled.div`
  border: solid 2px #fff;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${spacing("m")};

  svg {
    width: 64px;
    height: 64px;

    g {
      fill: #fff;
      stroke: #fff;
      stroke-width: 2px;
    }
  }
`;
