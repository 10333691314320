import * as React from "react";
import { SVGProps } from "react";

const SvgWebsite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.5,8.52h10v8h-10V8.52z M14.5,8.52h5v8h-5V8.52z M4.5,12.52h10"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.5,4.5h17v13c0,1.11-0.89,2-2,2h-15c-1.11,0-2-0.89-2-2v-11C2.5,5.39,3.39,4.5,4.5,4.5z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgWebsite;
