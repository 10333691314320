import * as React from "react";
import { SVGProps } from "react";

const SvgConnectivity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.042,4.669c4.049,3.337,4.626,9.324,1.289,13.373s-9.324,4.626-13.373,1.289S1.333,10.007,4.669,5.958 C6.474,3.769,9.163,2.5,12,2.5C14.205,2.498,16.342,3.265,18.042,4.669"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.163,7.875c0.571,0.518,1.094,1.087,1.562,1.7c1.837,2.422,2.762,5.414,2.612,8.45"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.198,4.477c2.469,0.006,4.891,0.678,7.011,1.944"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.734,18.18c0.201,1.148,0.552,2.264,1.044,3.32"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.39,8.012c-1.784,2.19-2.785,4.913-2.843,7.738"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.8,4.476c-0.968,0.493-1.875,1.096-2.704,1.798"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.431,7.06c1.784-0.001,3.552,0.335,5.211,0.991"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.884,10.3c1.735-1.534,3.85-2.573,6.125-3.007"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.236,15.672c0.475-1.292,1.144-2.503,1.984-3.594"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.595,17.651c1.884,1.113,4.008,1.757,6.193,1.875"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.515,12.25c0.776,1.508,1.832,2.855,3.11,3.97"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.91,5.677C4.924,7.144,5.152,8.6,5.589,10"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={6.008}
        cy={11.147}
        r={1.219}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={10.581}
        cy={16.971}
        r={1.219}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={14.212}
        cy={7.112}
        r={1.219}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgConnectivity;
