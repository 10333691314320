export const Colors = {
  primary100: "#e60000",
  primary200: "#ffffff",
  primary300: "#4a4d4e",
  primary400: "#25282B",

  secondary100: "#00697c",
  secondary200: "#5e2750",
  secondary300: "#eb6100",
  secondary400: "#fecb00",
  secondary500: "#008a00",
  secondary600: "#9c2aa0",
  secondary700: "#bd0000",

  state100: "#bd0000",
  state200: "#eb6100",
  state300: "#008a00",
  state400: "#005ea5",
  state500: "#0096ad",
  state600: "#262626",
  stateHover: "#0d0d0d15",
  statePressed: "#0d0d0d30",

  monochrome100: "#ffffff",
  monochrome200: "#f2f2f2",
  monochrome300: "#bebebe",
  monochrome400: "#7e7e7e",
  monochrome500: "#262626",
  monochrome600: "#0d0d0d",

  /**
   *  TODO: these mappings are not official and should be replaced when available
   */
  darkFont: "#0d0d0d",
  bgPanel100: "#ffffff",
  bgPanel200: "#f2f2f2",
  bgPanel300: "#f2f2f2",
  buttonTertiaryBorder: "#bebebe",
  buttonTertiaryBorderHover: "#262626",
  buttonTertiaryBgHover: "#0d0d0d26",
  buttonCircularColor: "#e60000",
  bgButtonGroup: "#f2f2f2",
  footerBg: "#0d0d0d",

  headerDarkGrey: "#333333",
  headerBorderChevron: "#ffffff",
  headerAnthracite: "#666666",
  headerAluminium: "#ebebeb",
  headerRed: "#e60000",
  headerWhiteBg: "#ffffff",
  headerLightGrey: "#f4f4f4",
  headerActiveColor: "#e60000",
  mddColor: "#333333",
  mddHoverColor: "#e60000",
  tableRowBgColor: "#ffffff",
  tableSubHeadlineBg: "#f2f2f2",
  tableSubHeadlineColor: "#0d0d0d",

  // Button Colors
  buttonTertiaryOpt1Bg: "#ffffff",
  buttonTertiaryOpt2Color: "#d0d0d0",
  buttonTertiary1Border: "#7E7E7E",
  buttonTertiary1BorderHover: "#262626",
  buttonTertiary2Border: "#7E7E7E",
  buttonTertiary2BorderHover: "#262626",

  //Toggle Colors
  toggleSliderBg: "#7e7e7e",
  togglePill: "#f2f2f2",
  togglePillChecked: "#ffffff",
  //percentage batteryColors
  gradient_0LastColor: "#FF0000",
  gradient_25LastColor: "#FF8400",
  gradient_50LastColor: "#F9FF00",
  gradient_75LastColor: "#A8FF00",
  gradient_100LastColor: "#23FF00",
  /**
   * Section end
   */
};

export const Gradients = {
  gradientPrimary100: `background-image: linear-gradient(to top right, #820000, #e60000);`,
  gradientPrimary200: `background-image: linear-gradient(to top right, #262626, #333333);`,
  gradientPrimary300: `background-image: linear-gradient(to top right, #f2f2f2, #fafafa);`,

  gradientSecondary100: `background-image: linear-gradient(to top right, #00697c, #0096ad);`,
  gradientSecondary200: `background-image: linear-gradient(to top right, #5e2750, #9c2aa0);`,
  gradientSecondary300: `background-image: linear-gradient(to top right, #008a00, #a8b400);`,
  gradientSecondary400: `background-image: linear-gradient(to top right, #eb9700, #fecb00);`,
  gradient_25porcent: `background-image: linear-gradient(0deg, rgba(255,0,0,1) 0%, rgba(255,132,0,1) 70%);`,
  gradient_50porcent: `background-image: linear-gradient(0deg, rgba(255,132,0,1) 0%, rgba(249,255,0,1) 28%);`,
  gradient_75porcent: `background-image: linear-gradient(0deg,rgba(249,255,0,1) 0%, rgba(168,255,0,1) 28%);`,
  gradient_100porcent: `background-image: linear-gradient(0deg, rgba(168,255,0,1) 0%, rgba(35,255,0,1) 28%);`,

  gradientDarkOnly: `background-image: linear-gradient(to top right, #262626, #333333);`,
};

export const States = {
  hover: `background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPkVQMAAEYANddShWEAAAAASUVORK5CYII=);`,
  pressed: `background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPk9QEAAGwAW0LheG0AAAAASUVORK5CYII=);`,
  hoverDarkmodeInvers: `background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPkVQMAAEYANddShWEAAAAASUVORK5CYII=);`,
  pressedDarkmodeInvers: `background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPk9QEAAGwAW0LheG0AAAAASUVORK5CYII=);`,
};

export const DarkThemeColors = {
  primary100: "#ec2f2f",
  primary200: "#ffffff",
  primary300: "#4a4d4e",
  primary400: "#25282B",

  secondary100: "#0096ad",
  secondary200: "#b04a98",
  secondary300: "#eb9700",
  secondary400: "#fecb00",
  secondary500: "#b7bf10",
  secondary600: "#d162d5",
  secondary700: "#f06666",

  state100: "#f06666",
  state200: "#eb9700",
  state300: "#b7bf10",
  state400: "#5f9bcb",
  state500: "#00b0ca",
  state600: "#262626",
  stateHover: "#ffffff15",
  statePressed: "#ffffff30",

  monochrome100: "#0d0d0d",
  monochrome200: "#262626",
  monochrome300: "#7e7e7e",
  monochrome400: "#bebebe",
  monochrome500: "#f2f2f2",
  monochrome600: "#ffffff",

  /**
   *  TODO: these mappings are not official and should be replaced when available
   */
  darkFont: "#0d0d0d",
  bgPanel100: "#262626",
  bgPanel200: "#262626",
  bgPanel300: "#0d0d0d",
  buttonTertiaryBorder: "#ffffff",
  buttonTertiaryBorderHover: "#ffffff",
  buttonTertiaryBgHover: "#ffffff26",
  buttonCircularColor: "#ffffff",
  bgButtonGroup: "#f2f2f2",
  footerBg: "#0d0d0d",

  headerDarkGrey: "#ffffff",
  headerBorderChevron: "#4a4d4e",
  headerAnthracite: "#7e7e7e",
  headerAluminium: "#4a4d4e",
  headerRed: "#e60000",
  headerWhiteBg: "#262626",
  headerLightGrey: "#7e7e7e",
  headerActiveColor: "#ffffff",
  mddColor: "#bebebe",
  mddHoverColor: "#f2f2f2",
  tableRowBgColor: "#262626",
  tableSubHeadlineBg: "#7e7e7e",
  tableSubHeadlineColor: "#0d0d0d",

  // Button Colors
  buttonTertiaryOpt1Bg: "transparent",
  buttonTertiaryOpt2Color: "#d0d0d0",
  buttonTertiary1Border: "#ffffff",
  buttonTertiary1BorderHover: "#ffffff",
  buttonTertiary2Border: "transparent",
  buttonTertiary2BorderHover: "transparent",

  //Toggle Colors
  toggleSliderBg: "#7e7e7e",
  togglePill: "#ffffff",
  togglePillChecked: "#ffffff",
  //percentage batteryColors
  gradient_0LastColor: "#FF0000",
  gradient_25LastColor: "#FF8400",
  gradient_50LastColor: "#F9FF00",
  gradient_75LastColor: "#A8FF00",
  gradient_100LastColor: "#23FF00",
  /**
   * Section end
   */
};

export const DarkThemeGradients = {
  gradientPrimary100: `background-image: linear-gradient(to top right, #820000, #e60000);`,
  gradientPrimary200: `background-image: linear-gradient(to top right, #f2f2f2, #fafafa);`,
  gradientPrimary300: `background-image: linear-gradient(to top right, #262626, #333333);`,

  gradientSecondary100: `background-image: linear-gradient(to top right, #0096ad, #00b0ca);`,
  gradientSecondary200: `background-image: linear-gradient(to top right, #9c2aa0, #d162d5);`,
  gradientSecondary300: `background-image: linear-gradient(to top right, #008a00, #a8b400);`,
  gradientSecondary400: `background-image: linear-gradient(to top right, #eb9700, #fecb00);`,

  gradient_25porcent: `background-image: linear-gradient(0deg, rgba(255,0,0,1) 0%, rgba(255,132,0,1) 70%);`,
  gradient_50porcent: `background-image: linear-gradient(0deg, rgba(255,132,0,1) 0%, rgba(249,255,0,1) 28%);`,
  gradient_75porcent: `background-image: linear-gradient(0deg,rgba(249,255,0,1) 0%, rgba(168,255,0,1) 28%);`,
  gradient_100porcent: `background-image: linear-gradient(0deg, rgba(168,255,0,1) 0%, rgba(35,255,0,1) 28%);`,

  gradientDarkOnly: `background-image: linear-gradient(to top right, #262626, #333333);`,
};

export const DarkThemeStates = {
  hover: `background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8rwYAAioBJ+mp4R0AAAAASUVORK5CYII=);`,
  pressed: `background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP87wMAAlABTQluYBcAAAAASUVORK5CYII=);`,
  hoverDarkmodeInvers: `background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPkVQMAAEYANddShWEAAAAASUVORK5CYII=);`,
  pressedDarkmodeInvers: `background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPk9QEAAGwAW0LheG0AAAAASUVORK5CYII=);`,
};
