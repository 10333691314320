import * as React from "react";
import { SVGProps } from "react";

const SvgMilitaryautoRFID = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="svg4239"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 507.74 313.75"
  >
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html:
            ".cls-1,.cls-2{fill:#313033;}.cls-3,.cls-4{fill:#4d4c50;}.cls-5{fill:#637445;}.cls-5,.cls-2,.cls-6,.cls-4,.cls-7,.cls-8,.cls-9,.cls-10,.cls-11,.cls-12,.cls-13,.cls-14,.cls-15{fill-rule:evenodd;}.cls-6{fill:#828186;}.cls-7{fill:#70b9d2;}.cls-8{fill:#718052;}.cls-9{fill:#819263;}.cls-10{fill:#99a87c;}.cls-11{fill:#9e9ca3;}.cls-12{fill:#8ed0e6;}.cls-13{fill:#e66626;}.cls-14{fill:#fcef7e;}.cls-15{fill:#69676c;}",
        }}
      />
    </defs>
    <path
      className="cls-14"
      d="m13.75,184.67c7.57,0,13.75-9.19,13.75-20.46s-6.18-20.46-13.75-20.46S0,152.94,0,164.21s6.18,20.46,13.75,20.46Z"
    />
    <rect className="cls-1" x="7.08" y="142.47" width="20.42" height="43.47" />
    <rect className="cls-3" x="209.95" width="96.42" height="120.57" />
    <path
      className="cls-13"
      d="m497.53,179.4c5.62,0,10.21-6.82,10.21-15.19s-4.59-15.2-10.21-15.2-10.21,6.83-10.21,15.2,4.59,15.19,10.21,15.19Z"
    />
    <rect
      className="cls-1"
      x="487.32"
      y="148.07"
      width="15.16"
      height="32.28"
    />
    <path
      className="cls-10"
      d="m44.54,124.86l2.3-8.83c2.42-9.28,9.66-16.52,18.94-18.94l149.05-7.82-21.41,40.93-148.89-5.33Z"
    />
    <polygon
      className="cls-2"
      points="16.28 209.08 77.4 244.37 184.27 244.37 197.59 257.69 280.45 257.69 371.7 233.24 437.4 233.24 481.26 207.91 481.26 166.92 16.28 166.92 16.28 209.08"
    />
    <path
      className="cls-4"
      d="m104.55,312.43c34.47,0,62.59-28.11,62.59-62.58s-28.11-62.59-62.59-62.59-62.59,28.12-62.59,62.59,28.11,62.58,62.59,62.58Z"
    />
    <path
      className="cls-6"
      d="m104.55,286.73c20.32,0,36.89-16.57,36.89-36.89s-16.57-36.89-36.89-36.89-36.89,16.57-36.89,36.89,16.57,36.89,36.89,36.89Z"
    />
    <path
      className="cls-15"
      d="m104.55,280.15c16.69,0,30.3-13.61,30.3-30.3s-13.61-30.31-30.3-30.31-30.3,13.61-30.3,30.31,13.61,30.3,30.3,30.3Z"
    />
    <path
      className="cls-11"
      d="m104.55,262.85c7.17,0,13.01-5.84,13.01-13.01s-5.85-13.01-13.01-13.01-13.01,5.85-13.01,13.01,5.84,13.01,13.01,13.01Z"
    />
    <path
      className="cls-4"
      d="m104.54,219.54c16.74,0,30.31,13.57,30.31,30.31,0,1.74-.15,3.44-.43,5.1-2.43-14.3-14.88-25.2-29.87-25.2s-27.45,10.89-29.87,25.2c-.28-1.66-.43-3.36-.43-5.1,0-16.74,13.57-30.31,30.3-30.31Z"
    />
    <path
      className="cls-6"
      d="m100.63,185.94h7.82v4.03h-7.82v-4.03h0Zm-59.99,67.81v-7.82h4.04v7.82h-4.04Zm127.8-7.82v7.82h-4.03v-7.82h4.03Zm-59.99,67.82h-7.82v-4.04h7.82v4.04Z"
    />
    <path
      className="cls-6"
      d="m84.23,189.13l7.56-2.02,1.04,3.9-7.56,2.02-1.04-3.9h0Zm-40.39,81.03l-2.02-7.56,3.9-1.04,2.03,7.56-3.9,1.05h0Zm121.42-40.64l2.02,7.56-3.9,1.05-2.02-7.56,3.9-1.04h0Zm-40.39,81.03l-7.56,2.02-1.04-3.9,7.56-2.02,1.04,3.9Z"
    />
    <path
      className="cls-6"
      d="m69.21,196.46l6.78-3.91,2.02,3.49-6.77,3.91-2.02-3.49h0Zm-18.05,88.72l-3.91-6.77,3.49-2.02,3.91,6.78-3.49,2.02h0Zm106.77-70.68l3.91,6.78-3.49,2.02-3.91-6.77,3.49-2.02h0Zm-18.05,88.72l-6.77,3.91-2.02-3.49,6.78-3.91,2.02,3.49Z"
    />
    <path
      className="cls-6"
      d="m56.59,207.42l5.53-5.53,2.85,2.85-5.53,5.53-2.85-2.85h0Zm5.53,90.37l-5.53-5.53,2.85-2.85,5.53,5.53-2.85,2.85h0Zm84.84-95.9l5.53,5.53-2.85,2.85-5.53-5.53,2.85-2.85h0Zm5.53,90.37l-5.53,5.53-2.85-2.85,5.53-5.53,2.85,2.85Z"
    />
    <path
      className="cls-6"
      d="m47.25,221.28l3.91-6.77,3.49,2.02-3.91,6.77-3.49-2.02h0Zm28.73,85.86l-6.77-3.91,2.02-3.49,6.78,3.91-2.02,3.5h0Zm57.13-114.59l6.78,3.91-2.02,3.49-6.77-3.91,2.02-3.49h0Zm28.73,85.86l-3.91,6.78-3.49-2.02,3.91-6.77,3.49,2.02Z"
    />
    <path
      className="cls-6"
      d="m41.81,237.08l2.02-7.56,3.9,1.04-2.02,7.56-3.9-1.04h0Zm49.98,75.5l-7.56-2.02,1.04-3.9,7.56,2.02-1.04,3.9h0Zm25.52-125.47l7.55,2.02-1.04,3.9-7.56-2.02,1.05-3.9h0Zm49.98,75.5l-2.02,7.56-3.9-1.04,2.02-7.56,3.9,1.05Z"
    />
    <path
      className="cls-4"
      d="m409.76,312.43c34.47,0,62.58-28.11,62.58-62.58s-28.11-62.59-62.58-62.59-62.59,28.12-62.59,62.59,28.12,62.58,62.59,62.58Z"
    />
    <path
      className="cls-6"
      d="m409.76,286.73c20.32,0,36.89-16.57,36.89-36.89s-16.57-36.89-36.89-36.89-36.89,16.57-36.89,36.89,16.57,36.89,36.89,36.89Z"
    />
    <path
      className="cls-15"
      d="m409.76,280.15c16.69,0,30.3-13.61,30.3-30.3s-13.61-30.31-30.3-30.31-30.3,13.61-30.3,30.31,13.61,30.3,30.3,30.3Z"
    />
    <path
      className="cls-11"
      d="m409.76,262.85c7.16,0,13.01-5.84,13.01-13.01s-5.85-13.01-13.01-13.01-13.01,5.85-13.01,13.01,5.85,13.01,13.01,13.01Z"
    />
    <path
      className="cls-4"
      d="m409.76,219.54c16.74,0,30.3,13.57,30.3,30.31,0,1.74-.15,3.44-.43,5.1-2.43-14.3-14.88-25.2-29.87-25.2s-27.45,10.89-29.87,25.2c-.28-1.66-.43-3.36-.43-5.1,0-16.74,13.57-30.31,30.3-30.31Z"
    />
    <path
      className="cls-6"
      d="m405.84,185.94h7.82v4.03h-7.82v-4.03h0Zm-59.99,67.81v-7.82h4.03v7.82h-4.03Zm127.8-7.82v7.82h-4.04v-7.82h4.04Zm-59.99,67.82h-7.82v-4.04h7.82v4.04Z"
    />
    <path
      className="cls-6"
      d="m389.44,189.13l7.56-2.02,1.04,3.9-7.55,2.02-1.05-3.9h0Zm-40.39,81.03l-2.02-7.56,3.9-1.04,2.02,7.56-3.9,1.05h0Zm121.43-40.64l2.02,7.56-3.9,1.05-2.02-7.56,3.9-1.04h0Zm-40.4,81.03l-7.56,2.02-1.05-3.9,7.56-2.02,1.04,3.9Z"
    />
    <path
      className="cls-6"
      d="m374.42,196.46l6.78-3.91,2.02,3.49-6.77,3.91-2.02-3.49h0Zm-18.05,88.72l-3.91-6.77,3.49-2.02,3.91,6.78-3.49,2.02h0Zm106.77-70.68l3.91,6.78-3.49,2.02-3.91-6.77,3.5-2.02h0Zm-18.05,88.72l-6.78,3.91-2.02-3.49,6.77-3.91,2.02,3.49Z"
    />
    <path
      className="cls-6"
      d="m361.8,207.42l5.53-5.53,2.85,2.85-5.53,5.53-2.85-2.85h0Zm5.53,90.37l-5.53-5.53,2.85-2.85,5.53,5.53-2.85,2.85h0Zm84.84-95.9l5.53,5.53-2.85,2.85-5.53-5.53,2.85-2.85h0Zm5.53,90.37l-5.53,5.53-2.85-2.85,5.53-5.53,2.85,2.85Z"
    />
    <path
      className="cls-6"
      d="m352.46,221.28l3.91-6.77,3.49,2.02-3.91,6.77-3.49-2.02h0Zm28.73,85.86l-6.78-3.91,2.02-3.49,6.77,3.91-2.02,3.5h0Zm57.13-114.59l6.77,3.91-2.02,3.49-6.78-3.91,2.02-3.49h0Zm28.73,85.86l-3.91,6.78-3.5-2.02,3.91-6.77,3.5,2.02Z"
    />
    <path
      className="cls-6"
      d="m347.02,237.08l2.02-7.56,3.9,1.04-2.02,7.56-3.9-1.04h0Zm49.98,75.5l-7.56-2.02,1.04-3.9,7.56,2.02-1.05,3.9h0Zm25.52-125.47l7.56,2.02-1.04,3.9-7.56-2.02,1.04-3.9h0Zm49.98,75.5l-2.02,7.56-3.9-1.04,2.02-7.56,3.9,1.05Z"
    />
    <path
      className="cls-9"
      d="m306.8,116.03h190.23v91.88h-28.26c-13.12-18.44-34.66-30.46-59.01-30.46s-45.89,12.02-59.02,30.46h-43.94l-121.22,29.83h-9.67c-5.76-34.22-35.52-60.28-71.38-60.28-25.53,0-47.97,13.21-60.86,33.18l-31.69-7.15v-53.69c0-18.83,15.26-34.09,34.09-34.09l139.5-7.61,121.22,7.94Z"
    />
    <path
      className="cls-8"
      d="m497.03,155.81v52.1h-28.26c-13.12-18.44-34.66-30.46-59.01-30.46s-45.89,12.02-59.02,30.46h-43.94l-121.22,29.83h-9.67c-5.76-34.22-35.52-60.28-71.38-60.28-25.53,0-47.97,13.21-60.86,33.18l-31.69-7.15v-47.67h485.04Z"
    />
    <polygon
      className="cls-8"
      points="185.59 108.09 209.74 11.99 306.8 11.99 306.8 116.03 306.8 207.91 280.78 237.74 185.59 237.74 185.59 108.09"
    />
    <polygon
      className="cls-5"
      points="306.8 155.81 306.8 207.91 280.78 237.74 185.59 237.74 185.59 155.81 306.8 155.81"
    />
    <polygon
      className="cls-9"
      points="185.59 108.09 209.74 11.99 306.8 11.99 306.8 108.09 185.59 108.09"
    />
    <polygon
      className="cls-7"
      points="299.87 89.27 299.87 18.92 215.14 18.92 197.46 89.27 299.87 89.27"
    />
    <polygon
      className="cls-12"
      points="291.21 79.3 291.21 28.89 230.49 28.89 217.82 79.3 291.21 79.3"
    />
  </svg>
);

export default SvgMilitaryautoRFID;
