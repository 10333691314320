import * as React from "react";
import { SVGProps } from "react";

const SvgVoc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.6,6.2c-1.7,0-3.2,1-4,2.4C9.2,8.4,8.7,8.2,8.2,8.2 c-1.5,0-2.8,1.3-2.8,2.8c0,0.2,0,0.4,0.1,0.6c-1.2,0.5-2.1,1.6-2.1,3c0,1.8,1.4,3.2,3.2,3.2h10c2.2,0,4-1.8,4-4 c0-1.7-1-3.1-2.5-3.7C17.8,7.9,15.9,6.2,13.6,6.2L13.6,6.2z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M17,12.1c-0.1-0.1-0.3-0.4-0.4-0.5s-0.4-0.2-0.6-0.2c-0.2,0-0.3,0-0.5,0.1c-0.2,0.1-0.3,0.2-0.4,0.4 c-0.1,0.2-0.2,0.3-0.3,0.6c-0.1,0.2-0.1,0.5-0.1,0.8c0,0.4,0.1,0.8,0.2,1c0,0,0,0,0,0c0.1,0.3,0.3,0.5,0.5,0.6 c0.2,0.1,0.4,0.2,0.7,0.2c0.2,0,0.4,0,0.6-0.1c0,0,0,0,0,0c0.2-0.1,0.3-0.2,0.4-0.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.2,15.2c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.3-0.2-0.6-0.2-1c0-0.4,0.1-0.7,0.2-1 c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.4-0.2,0.7-0.2c0.3,0,0.5,0.1,0.7,0.2c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.3,0.2,0.6,0.2,1 c0,0.4-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.5-0.5,0.7C12.7,15.1,12.5,15.2,12.2,15.2z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        vectorEffect="non-scaling-stroke"
        points="7.5,11.3 8.9,15.2 10.2,11.3  "
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgVoc;
