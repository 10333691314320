import React, { useContext, useEffect, useState } from "react";
import * as S from "./Menu.styles";
import { MenuProps } from "./Menu.types";
import { BurgerMenu } from "./";
import { useAccount, useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { dataRequest } from "../../../config/config";
import {
  callApiData,
  dataEndpointConfig,
  ProjectProps,
  filterSupportedProjects,
} from "../../../data";
import { Icon } from "../Icon";
import { themeModeContext } from "../MasterPage";

export const Menu: React.FC<MenuProps> = ({ open, light }) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!open);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [projects, setProjects] = useState<ProjectProps[]>([]);
  const { themeStatus, setThemeStatus } = useContext(themeModeContext);

  useEffect(() => {
    const user_projects = sessionStorage.getItem("user_projects");
    if (user_projects) {
      setProjects(JSON.parse(user_projects));
      return;
    }

    if (account && inProgress === InteractionStatus.None && !user_projects) {
      const request = {
        ...dataRequest,
        account: account,
      };
      const getProjectsListQuery = dataEndpointConfig.projects.fields;
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          callApiData(response.accessToken, getProjectsListQuery).then(
            (response) => {
              const filteredProjects = filterSupportedProjects(response);
              setProjects(filteredProjects);
              sessionStorage.setItem(
                "user_projects",
                JSON.stringify(filteredProjects),
              );
            },
          );
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(request);
          }
        });
    }
  }, [account, inProgress, instance]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <>
      <BurgerMenu onClick={() => toggleOpen()} isOpen={isOpen} light={light} />
      <S.MenuOverlay open={isOpen} onClick={() => toggleOpen()}>
        <S.MenuWrapper open={isOpen}>
          {projects &&
            projects.map((project: ProjectProps) => {
              const url = project.routeSecondLayer
                ? project.route + "?group=" + project.routeSecondLayer
                : project.route;

              return (
                <a key={project.id} href={url}>
                  <Icon as={project.ws10Icon} />
                  {project.name}
                </a>
              );
            })}
          <S.BottomWrapper>
            <S.Btn onClick={() => handleLogout()} aria-label={"Logout"}>
              <Icon as={"LogOut"} />
            </S.Btn>
            <S.Btn
              aria-label={"Theme Switcher"}
              onClick={() => setThemeStatus(true)}
            >
              <Icon as={"DarkMode"} />
            </S.Btn>
          </S.BottomWrapper>
        </S.MenuWrapper>
      </S.MenuOverlay>
    </>
  );
};

export default Menu;
