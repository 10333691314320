import * as React from "react";
import { SVGProps } from "react";

const SvgIdea = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.344,9.125c-0.004,1.074-0.297,2.129-0.852,3.051c-0.227,0.383-0.48,0.75-0.758,1.098 c0,0-1.34,1.277-1.34,2.863c0,0.129-0.051,0.254-0.145,0.344c-0.09,0.094-0.215,0.145-0.344,0.145H9.051 c-0.129,0-0.254-0.051-0.348-0.145c-0.09-0.09-0.141-0.215-0.141-0.344c0-1.586-1.297-2.867-1.297-2.867 c-0.277-0.348-0.531-0.715-0.758-1.098C5.953,11.25,5.66,10.195,5.656,9.121V9.105c0-0.098,0-0.195,0-0.293V8.797 c0.156-3,2.609-5.715,6.109-5.836h0.449c3.773,0,6.117,3.262,6.117,5.855L18.344,9.125z M8.125,9.332 c0-1.387,0.73-2.668,1.922-3.367 M8.805,18.113h6.391 M9.523,19.578h4.957 M10.309,21.043h3.438"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgIdea;
