import styled from "styled-components";
import { SmallButton } from "../SmallButton/SmallButton.styles";
import { RectangularButton } from "../RectangularButton";

export const ChartWrapper = styled.div`
  height: 400px;
`;

export const ChartControls = styled.div`
  margin-top: 24px;
`;

export const TabGroup = styled.div`
  padding: 2px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.bgPanel300};
  display: inline-block;

  ${SmallButton} {
    box-shadow: none;
    margin-right: 1px;
    margin-left: 1px;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Button = styled(RectangularButton)``;
